export var ABTestState;
(function (ABTestState) {
    ABTestState[ABTestState["WaitingToStart"] = 0] = "WaitingToStart";
    ABTestState[ABTestState["Running"] = 1] = "Running";
    ABTestState[ABTestState["Completed"] = 2] = "Completed";
    ABTestState[ABTestState["NoNewUser"] = 3] = "NoNewUser";
    ABTestState[ABTestState["Cancelled"] = 4] = "Cancelled";
    ABTestState[ABTestState["PausedForToday"] = 5] = "PausedForToday";
    ABTestState[ABTestState["TargetUserCountReached"] = 6] = "TargetUserCountReached";
})(ABTestState || (ABTestState = {}));
