export var FilterCondition;
(function (FilterCondition) {
    FilterCondition[FilterCondition["NoOp"] = 0] = "NoOp";
    FilterCondition[FilterCondition["Equal"] = 1] = "Equal";
    FilterCondition[FilterCondition["NotEqual"] = 2] = "NotEqual";
    FilterCondition[FilterCondition["GreaterThan"] = 3] = "GreaterThan";
    FilterCondition[FilterCondition["LessThan"] = 4] = "LessThan";
    FilterCondition[FilterCondition["GreaterThanOrEqual"] = 5] = "GreaterThanOrEqual";
    FilterCondition[FilterCondition["LessThanOrEqual"] = 6] = "LessThanOrEqual";
    FilterCondition[FilterCondition["Include"] = 7] = "Include";
    FilterCondition[FilterCondition["Exclude"] = 8] = "Exclude";
})(FilterCondition || (FilterCondition = {}));
export var FilterConditionString;
(function (FilterConditionString) {
    FilterConditionString["NoOp"] = "NoOp";
    FilterConditionString["Equal"] = "Equal";
    FilterConditionString["NotEqual"] = "NotEqual";
    FilterConditionString["GreaterThan"] = "GreaterThan";
    FilterConditionString["LessThan"] = "LessThan";
    FilterConditionString["GreaterThanOrEqual"] = "GreaterThanOrEqual";
    FilterConditionString["LessThanOrEqual"] = "LessThanOrEqual";
    FilterConditionString["Include"] = "Include";
    FilterConditionString["Exclude"] = "Exclude";
})(FilterConditionString || (FilterConditionString = {}));
