import { object, string } from "zod";

// Define the login validation schema
export const loginFormSchema = object({
  email: string()
    .email("Please enter a valid email")
    .refine((email) => email.endsWith("@alictus.com") || email.endsWith("@sciplay.com"), {
      message: "Email must be from @alictus.com or @sciplay.com domain",
    }),
  password: string().min(8, "Password must be at least 8 characters"),
});
