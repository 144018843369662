import React, {useEffect} from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    VStack
} from '@chakra-ui/react';

import {Form, Formik, FormikValues} from 'formik';
import abTestValidationSchema from './validationSchema';
import BasicInfo from './components/BasicInfo';
import Targeting from './components/Targeting';
import Distribution from './components/Distribution';
import Variants from './components/Variants';
import {ABTest} from "../types";
import {ABTestState} from "../types/enums/ab_test_state";
import {UserTargeting} from "../types/enums/user_targeting";
import {createExperimentRequest, updateExperiment} from "./abTestApi";
import globalStore, {GlobalStore} from "../store";
import {Platform} from "../types/enums/platform";

export function ABTestModal(
    {
        title,
        isOpen,
        onClose,
        abTestToUpdate= null
    }: {
        title: string;
        isOpen: boolean;
        onClose: () => void;
        abTestToUpdate: ABTest | null;
}) {

    let store = globalStore();
    const toast = useToast();
    console.log("ABTestModal title: ", title);
    //const { store, rcValues, handleCreateExperiment, handleUpdateExperiment } = useAbTestForm(abTestToUpdate, isOpen, onClose);

    useEffect(() => {
        if (abTestToUpdate !== null && JSON.stringify(store.abTestModal) !== JSON.stringify(abTestToUpdate)) {
            store.setABTestModal(abTestToUpdate);
        } else if (abTestToUpdate === null && store.abTestModal === null) {
            store.setABTestModal(createDefaultABTest());
        }
    }, [abTestToUpdate, store]);



    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW="950px" border={'1px'} borderColor={'gray.700'}>
                <ModalHeader>{title}</ModalHeader>
                <Formik
                    initialValues={store.abTestModal as FormikValues}
                    validationSchema={abTestValidationSchema}
                    onSubmit={async (values, actions) => {
                        if (abTestToUpdate === null && store.abTestModal !== null) {
                            await handleCreateExperiment(store, store.abTestModal, toast, onClose);
                        }

                        if(abTestToUpdate !== null && store.abTestModal !== null){
                            await handleUpdateExperiment(store, store.abTestModal, toast, onClose);
                        }
                        actions.setSubmitting(false);
                    }}
                >
                    {(props) => (
                        console.log('Validation Errors:', props.errors),
                        <Form>
                            <ModalBody>
                                <VStack spacing={4}>
                                    <Accordion border={'1px'} borderColor={'gray.700'} width={'100%'}>
                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton backgroundColor={'gray.600'}>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Basics
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                {store.abTestModal && <BasicInfo abTestModal={store.abTestModal} updateAbTestModal={store.updateAbTestModal}  />}
                                            </AccordionPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton backgroundColor={'gray.600'}>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Targeting
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4} zIndex={9999}>
                                                {store.abTestModal && <Targeting abTestModal={store.abTestModal} updateAbTestModal={store.updateAbTestModal}  />}
                                            </AccordionPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton backgroundColor={'gray.600'}>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Distribution
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                {store.abTestModal && <Distribution abTestModal={store.abTestModal} updateAbTestModal={store.updateAbTestModal}  />}
                                            </AccordionPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <h2>
                                                <AccordionButton backgroundColor={'gray.600'}>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        Variants
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Variants
                                                    dataStore={store}
                                                    rcValues={store.remoteConfigValues}
                                                />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </VStack>
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="ghost" mr={3} onClick={onClose}>
                                    Close
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    mr={3}
                                    isLoading={props.isSubmitting}
                                    isDisabled={!props.isValid}
                                    type="submit"
                                >
                                    {abTestToUpdate === null ? 'Create Experiment' : 'Update Experiment'}
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

const handleCreateExperiment = async (store: GlobalStore, abTest: ABTest, toast: any, onClose: () => void) => {

    try {
        //await abTestValidationSchema.validate(values, { abortEarly: false });
        //const payload = createAbTestPayload({ ...values, responseData });

        console.log('store.JWTToken:', store.JWTToken);
        console.log('store.gameId:', store.gameId);

        if(!store.JWTToken || !store.gameId)
        {
            throw new Error('Invalid user token or game id');
        }

        await createExperimentRequest(store.JWTToken, store.gameId, abTest);
        onClose();
        toast({
            title: "Experiment created.",
            description: "Your A/B test experiment has been successfully created.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    } catch (error) {
        console.error('Error creating experiment:', error);
        toast({
            title: "Error creating experiment.",
            description: error instanceof Error ? error.message : String(error),
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    }
};

const handleUpdateExperiment = async (store: GlobalStore, abTest: ABTest, toast:any, onClose: () => void) => {

    try {
        //await abTestValidationSchema.validate(values, { abortEarly: false });
        //const payload = createAbTestPayload({ ...values, responseData });
        if(!store.JWTToken || !store.gameId)
        {
            throw new Error('Invalid user token or game id');
        }

        await updateExperiment(store.JWTToken, store.gameId, abTest);
        onClose();
        toast({
            title: "Experiment updated.",
            description: "Your A/B test experiment has been successfully updated.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    } catch (error) {
        console.error('Error updating experiment:', error);
        toast({
            title: "Error updating experiment.",
            description: error instanceof Error ? error.message : String(error),
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    }
};

function createDefaultABTest(): ABTest {
    return {
        id: 0,
        uid: '',
        name: '',
        description: '',
        identifier: '',
        freezeRemoteConfig: false,
        isTargeted: false,
        dailyUserLimit: 0,
        totalMaximumUserCount: 0,
        creationDate: Date.now(),
        activationDate: null,
        lastUpdated: Date.now(),
        variants: [
            {
                uuid: '',
                name: 'Base Variant',
                values: []
            },
            {
                uuid: '',
                name: 'Variant 1',
                values: []
            }
        ],
        state: ABTestState.WaitingToStart,
        allowMultipleABTests: false,
        activationEventName: null,
        filterOperations: [],
        platform: Platform.All,
        userTargeting: UserTargeting.All,
        buildNumber: 0,
        buildNumberFilter: 0,
        version: null,
        versionFilter: 0,
        country: null,
        countryFilter: 0,
        createdBy: 0,
        updatedBy: 0,
        endDate: null,
        isDeleted: false,
        isOverWritten: false
    };
}

export default ABTestModal;
