import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Button,
    HStack,
    VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';

const CancelCodemagicBuildModal = ({userToken, build_id, disclosure}) => {
    const sendPostRequest = () => {
        let url = config.API_ENDPOINT + '/codemagic/build/' + build_id;
        axios.delete(url, {headers: {Authorization: "Bearer " + userToken}})
            .then((response) => {
                console.log(response);
                disclosure.onClose();
            });
    }

    return (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>Cancel Build</ModalHeader>
                <ModalCloseButton />
                <ModalBody >
                    <VStack>
                        <HStack>
                            <Button variant="outline" mr={3} onClick={disclosure.onClose}>Cancel</Button>
                            <Button variant="outline" bgColor={'red.400'}  mr={3} onClick={()=>sendPostRequest()}>Proceed</Button>
                        </HStack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CancelCodemagicBuildModal;
