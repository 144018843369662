import React from 'react';
import {
    FormControl,
    FormLabel,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    FormErrorMessage,
} from '@chakra-ui/react';
import { Field } from 'formik';
import {ABTest} from "../../types";

export function Distribution({abTestModal, updateAbTestModal }: {abTestModal: ABTest, updateAbTestModal: (test: Partial<ABTest>) => void}) {
    return (
        <>
            <Field name="dailyUserLimit">
                {({ field, form }: { field: any; form: any }) => (
                    <FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.dailyUserLimit && form.touched.dailyUserLimit}>
                        <FormLabel>Daily Maximum User Count</FormLabel>
                        <NumberInput
                            {...field}
                            value={abTestModal.dailyUserLimit}
                            onChange={(e: any) => {
                                if(e) {
                                    try {
                                        form.setFieldValue('dailyUserLimit', e);
                                        updateAbTestModal({dailyUserLimit: e});
                                        field.onChange(e);
                                    }
                                    catch (eerr) {
                                        console.log(eerr);
                                    }
                                }
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>{form.errors.dailyUserLimit}</FormErrorMessage>
                    </FormControl>
                )}
            </Field>

            <Field name="totalMaximumUserCount">
                {({ field, form }: { field: any; form: any }) => (
                    <FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.totalMaximumUserCount && form.touched.totalMaximumUserCount}>
                        <FormLabel>Total Maximum User Count</FormLabel>
                        <NumberInput
                            {...field}
                            value={abTestModal.totalMaximumUserCount}
                            onChange={(e: any) => {
                                if(e) {
                                    try {
                                        console.log(e);
                                        form.setFieldValue('totalMaximumUserCount', e);
                                        updateAbTestModal({totalMaximumUserCount: e});
                                        field.onChange(e);
                                    }
                                    catch (eerr) {
                                        console.log(eerr);
                                    }
                                }
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>{form.errors.totalMaximumUserCount}</FormErrorMessage>
                    </FormControl>
                )}
            </Field>
        </>
    );
};

export default Distribution;
