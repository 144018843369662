import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Button,
    HStack,
    VStack,
} from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from "../store";

export function DeleteRCKeyModal({
                                     rcKey,
                                     rcValueId,
                                     disclosure
                                 }: {
    rcKey: string;
    rcValueId: number;
    disclosure: { isOpen: boolean; onClose: () => void };
}) {
    const [isDeleting, setIsDeleting] = React.useState(false);
    let globalData = globalStore();

    function deleteRCValue(rcValueId: number) {
        setIsDeleting(true);
        let url = config.API_ENDPOINT + '/game/' + globalData.gameId + '/rc_package/1' + '/rc_value/' + rcValueId;
        axios.delete(url,
            { headers: { Authorization: "Bearer " + globalData.JWTToken } })
            .then((response) => {
                if(response.status === 200) {
                    console.log(response);
                    //Remove deleted RC Value from the global store
                    globalData.setRemoteConfigValues(globalData.remoteConfigValues.filter((rcValue) => rcValue.id !== rcValueId));
                    disclosure.onClose();
                    setIsDeleting(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setIsDeleting(false);
            });
    }

    return (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>Delete {rcKey}</ModalHeader>
                <ModalCloseButton />
                <ModalBody >
                    <VStack>
                        <HStack>
                            <Button variant="outline" mr={3} onClick={disclosure.onClose}>Cancel</Button>
                            {isDeleting ? (
                                <Spinner />
                            ) : (
                                <Button variant="outline" bgColor={'red.400'} mr={3} onClick={() => {
                                    if (globalData.JWTToken) {
                                        deleteRCValue(rcValueId);
                                    } else {
                                        console.error("JWTToken is null");
                                    }
                                }}>Proceed</Button>
                            )}
                        </HStack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default DeleteRCKeyModal;
