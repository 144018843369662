import {RemoteConfigValue} from "../types";

export class ViewFilter {
    public ViewOnlyActive: boolean;
    public ViewIsTestEnvironment: boolean | null;
    public ViewPrivate: boolean | null;
    public ViewVersion: string | null;
    public ExclusiveVersion: boolean;
    public ViewCountry: string | null;
    public ExclusiveCountry: boolean;
    public ViewPlatform: string | null;
    public ExclusivePlatform: boolean;

    constructor() {
        this.ViewOnlyActive = true;
        this.ViewVersion = null;
        this.ViewPrivate = null;
        this.ViewIsTestEnvironment = null;
        this.ExclusiveVersion = false;
        this.ViewCountry = null;
        this.ExclusiveCountry = false;
        this.ViewPlatform = null;
        this.ExclusivePlatform = false;
    }

    toggleViewOnlyActive(): ViewFilter {
        this.ViewOnlyActive = !this.ViewOnlyActive;
        return this;
    }

    checkIfFilterExists(rcValue: any, filterId: number): boolean {
        for (let i = 0; i < rcValue.FilterOperations.length; i++) {
            let filterOp = rcValue.FilterOperations[i];
            if (filterOp.Filter === filterId)
                return true;
        }
        return false;
    }

    canDisplay(rcValue: RemoteConfigValue): boolean {
        if (this.ViewOnlyActive && !rcValue.isActive)
            return false;

        if(this.ViewIsTestEnvironment !== null) {
            console.log(`ViewIsTestEnvironment: ${this.ViewIsTestEnvironment} and rcValue.isTest: ${rcValue.isTest} `);
            if(this.ViewIsTestEnvironment && !rcValue.isTest) return false;
            if(!this.ViewIsTestEnvironment && rcValue.isTest) return false;
        }

        if(this.ViewPrivate !== null) {
            console.log(`ViewIsTestEnvironment: ${this.ViewIsTestEnvironment} and rcValue.isTest: ${rcValue.isTest} `);
            if(this.ViewPrivate && !rcValue.isPersonal) return false;
            if(!this.ViewPrivate && rcValue.isPersonal) return false;
        }

        if (!this.hasFilter()) return true;
        if (this.hasExclusiveFilter()) {
            if (this.ViewVersion && this.ExclusiveVersion) {
                if (!this.checkIfFilterExists(rcValue, 2)) return false;
            }

            if (this.ViewCountry && this.ExclusiveCountry) {
                if (!this.checkIfFilterExists(rcValue, 1)) return false;
            }

            if (this.ViewPlatform && this.ExclusivePlatform) {
                if (!this.checkIfFilterExists(rcValue, 4)) return false;
            }
        }

        for (let i =.0; i < rcValue.filterOperations.length; i++) {
            let filterOp = rcValue.filterOperations[i];
            console.log(filterOp);
            if (filterOp.Filter === 2 && this.ViewVersion && !filterOp.Values.includes(this.ViewVersion))
                return false;
            if (filterOp.Filter === 1 && this.ViewCountry && !filterOp.Values.map((value) => value.toString().toLowerCase()).includes(this.ViewCountry!.toLowerCase()))
                return false;
            if (filterOp.Filter === 4 && this.ViewPlatform && !filterOp.Values.map((value) => value.toString().toLowerCase()).includes(this.ViewPlatform!.toLowerCase()))
                return false;
        }
        return true;
    }

    hasFilter(): boolean {
        return !!this.ViewVersion || !!this.ViewCountry || !!this.ViewPlatform || this.ViewIsTestEnvironment !== null;
    }

    hasExclusiveFilter(): boolean {
        return this.ExclusiveVersion || this.ExclusiveCountry || this.ExclusivePlatform;
    }
}