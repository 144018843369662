import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react'
import theme from './theme'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import LoginView from "./login/login_view";

const root = ReactDOM.createRoot(document.getElementById('root'));


function Main() {
    return (
        <React.StrictMode>
            <ChakraProvider theme={theme}>
                <Router>
                    <Routes>
                        <Route path="/" element={<LoginView />} />
                    </Routes>
                </Router>
            </ChakraProvider>
        </React.StrictMode>
    );
}

root.render(<Main />);

