
// Define filter conditions and types if not already defined

import {RCType} from "./types/remote_config";
import {FilterCondition} from "./types/enums/filter_condition";

/*export const FilterConditionString = {
    NoOp: 'NoOp',
    Equal: 'Equal',
    NotEqual: 'NotEqual',
    GreaterThan: 'GreaterThan',
    LessThan: 'LessThan',
    GreaterThanOrEqual: 'GreaterThanOrEqual',
    LessThanOrEqual: 'LessThanOrEqual',
    Include: 'Include',
    Exclude: 'Exclude'
};*/

export function RemoteConfigTypeToString(rcType) {
    switch(rcType) {
        case RCType.String:
            return 'String';
        case RCType.Integer:
            return 'Integer';
        case RCType.Float:
            return 'Float';
        case RCType.Boolean:
            return 'Boolean';
        default:
            throw new Error("Invalid remote config type");
    }
}

export const FilterTypes = {
    Country: 'Country',
    Version: 'Version',
    Platform: 'Platform'
};

/*
export function stringToFilterCondition(condition) : FilterCondition {
    switch(condition) {
        case 'NoOp':
            return FilterCondition.NoOp;
        case 'Equal':
            return FilterCondition.Equal;
        case 'NotEqual':
            return FilterCondition.NotEqual;
        case 'GreaterThan':
            return FilterCondition.GreaterThan;
        case 'LessThan':
            return FilterCondition.LessThan;
        case 'GreaterThanOrEqual':
            return FilterCondition.GreaterThanOrEqual;
        case 'LessThanOrEqual':
            return FilterCondition.LessThanOrEqual;
        case 'Include':
            return FilterCondition.Include;
        case 'Exclude':
            return FilterCondition.Exclude;
        default:
            throw new Error("Invalid filter condition");
    }
}
*/

export function FilterConditionToByte(condition) {
    switch(condition) {
        case FilterCondition.NoOp:
            return 0;
        case FilterCondition.Equal:
            return 1;
        case FilterCondition.NotEqual:
            return 2;
        case FilterCondition.GreaterThan:
            return 3;
        case FilterCondition.LessThan:
            return 4;
        case FilterCondition.GreaterThanOrEqual:
            return 5;
        case FilterCondition.LessThanOrEqual:
            return 6;
        case FilterCondition.Include:
            return 7;
        case FilterCondition.Exclude:
            return 8;
        default:
            return 0;
    }
}

export function byteToFilterConditionString(byte) {
    switch(byte) {
        case 0:
            return 'NoOp';
        case 1:
            return 'Equal';
        case 2:
            return 'NotEqual';
        case 3:
            return 'GreaterThan';
        case 4:
            return 'LessThan';
        case 5:
            return 'GreaterThanOrEqual';
        case 6:
            return 'LessThanOrEqual';
        case 7:
            return 'Include';
        case 8:
            return 'Exclude';
        default:
            return "Error";
    }
}

export function filterTypeToByte(type) {
    switch(type) {
        case "Country":
            return 1;
        case "Version":
            return 2;
        case "DeviceId":
            return 3;
        case "Platform":
            return 4;
        case "Date":
            return 5;
        case "BuildNumber":
            return 6;
        case "NewUser":
            return 7;
        case "AbTest":
            return 8;
        default:
            //throw error
            console.log(type + " is not a valid filter type")
            throw new Error("Invalid filter type");
    }
}

export function byteToFilterTypeString(byte) {
    switch(byte) {
        case 1:
            return 'Country';
        case 2:
            return 'Version';
        case 3:
            return 'DeviceId';
        case 4:
            return 'Platform';
        case 5:
            return 'Date';
        case 6:
            return 'BuildNumber';
        case 7:
            return 'NewUser';
        case 8:
            return 'AbTest';
        default:
            return "Error";
    }

}

export function arrayToString(array){
    if(array === undefined || array === null || array.length === 0) {
        return "";
    }

    if(!Array.isArray(array)) {
        return array;
    }

    let string = "";
    for(let i = 0; i < array.length; i++) {
        string += array[i];
        if(i !== array.length - 1) {
            string += ",";
        }
    }
    return string;
}

export function stringToArray(string) {
    if (typeof string !== 'string' || string.length === 0) {
        return [];
    }

    if (string.includes(',')) {
        return string.split(',').map(element => element.trim());
    }

    return [string.trim()];
}

export function populateFilters(initialFilter, filterData){
    let newFilters = [];

    if(filterData === undefined || filterData === null || filterData.length === 0) {
        newFilters.push(initialFilter);
        return newFilters;
    }

    for(let i = 0; i < filterData.length; i++) {
        let filter = filterData[i];
        newFilters.push({
            Filter: byteToFilterTypeString(filter.Filter),
            Condition: byteToFilterConditionString(filter.Condition),
            Values: arrayToString(filter.Values)
        });
    }

    newFilters.push(initialFilter);
    return newFilters;
}


export function formatValue(value, Type){
    if(Type === "Float") {
        return parseFloat(value);
    }
    else if(Type === "Integer") {
        return parseInt(value);
    }
    else if(Type === "Boolean") {
        return value === "true";
    }
    else {
        return value;
    }
}

export function formatFilters(filters){
    let formattedFilters = [];

    if(filters === undefined || filters === null || filters.length === 0) {
        return formattedFilters;
    }

    for (let i = 0; i < filters.length; i++) {

        /*if(filters[i].Values.includes(',')) {
            filters[i].Values = filters[i].Values.split(',');
        }*/

        if(filters[i].Condition === FilterCondition.NoOp) {
            continue;
        }

        if(filters[i].Values === "") {
            continue;
        }

/*        if(!Array.isArray(filters[i].Values)) {
            filters[i].Values = [filters[i].Values];
        }*/

        formattedFilters.push({
            Filter: filterTypeToByte(filters[i].Filter),
            Condition: FilterConditionToByte(filters[i].Condition),
            Values: stringToArray(filters[i].Values)
        });
    }

    console.log("formatted filters");
    console.log(formattedFilters);
    return formattedFilters;
}


export function formatAbFilters(filters){
    let formattedFilters = [];
    console.log("formatFilters");
    console.log(filters);

    if(filters === undefined || filters === null || filters.length === 0) {
        return formattedFilters;
    }

    for (let i = 0; i < filters.length; i++) {

        if(filters[i].Values.includes(',')) {
            filters[i].Values = filters[i].Values.split(',');
        }

        if(filters[i].Condition === FilterCondition.NoOp) {
            continue;
        }

        if(filters[i].Values === "") {
            continue;
        }

        if(!Array.isArray(filters[i].Values)) {
            filters[i].Values = [filters[i].Values];
        }

        formattedFilters.push({
            Filter: filterTypeToByte(filters[i].Filter),
            Condition: FilterConditionToByte(filters[i].Condition),
            Values: stringToArray(filters[i].Values)
        });
    }
    console.log("formatted filters");
    console.log(formattedFilters);
    return formattedFilters;
}

export function formatTimestamp(epoch) {
    if(epoch === undefined || epoch === null || epoch < 1) {
        return "-";
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(epoch * 1000);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day} ${months[monthIndex]} ${year} at ${hours}:${minutes}`;
}