import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    FormControl,
    FormLabel, HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Select,
    Stack,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from "../store";
import {DataSelector, PlayerSegment} from "../types/segment";
import {SegmentationFilterOperation} from "../types/runtime/filter_operation";
import {FilterCondition} from "../types/enums/filter_condition";
import DeleteSelectorModal from "../selector/delete_selector_modal";
import {useDisclosure} from "@chakra-ui/hooks";
import DeleteSegmentModal from "./delete_segment_modal";


const createEmptyUserSegment = (): PlayerSegment =>  {
    return {
        id: 0,
        uid: '',
        name: '',
        description: '',
        filterOperations: [],
        userId: 0,
        creationDate: 0,
        lastUpdated: 0,
        isTest: false,
        isDeleted: false,
        isOverWritten: false,
    }
};

function listFilterOperations(segmentFilter: SegmentationFilterOperation, dataSelectors: DataSelector[], handleSelectorSelection: any) {
    return <HStack spacing={4}>
        <Select
            placeholder="Select Selector"
            value={segmentFilter.SelectorUid}
            onChange={(e) => handleSelectorSelection(segmentFilter, {SelectorUid: e.target.value})}
        >
            {dataSelectors.map((selector => (
                <option key={selector.uid} value={selector.uid}>{selector.name}</option>
            )))}
        </Select>
        <Select
            placeholder="Select Condition"
            value={segmentFilter.Condition}
            onChange={(e) => handleSelectorSelection(segmentFilter, {Condition: e.target.value})}
        >
            {Object.entries(FilterCondition).map(([key, condition]) => (
                <option key={key} value={condition}>{condition}</option>
            ))}
        </Select>
        <Input
            value={segmentFilter.Values.toString()}
            onChange={(e) => handleSelectorSelection(segmentFilter, {Values: e.target.value})}
            placeholder="Filter Value"
        />
        {/*{index === filter.Filters.length - 1 ? (
                                    <Button width={'350px'} colorScheme="blue" onClick={handleAddFilter}>Add Filter</Button>
                                ) : (
                                    <Button width={'350px'} colorScheme="red" onClick={() => handleRemoveFilter(index)}>Remove Filter</Button>
                                )}*/}
    </HStack>
}

export function SegmentModal({title, isOpen, onClose, segmentInstance}: {title:string; isOpen: boolean; onClose: () => void; segmentInstance?: PlayerSegment}) {
    let globalData = globalStore();
    const [playerSegment, setPlayerSegment] = useState<PlayerSegment>(createEmptyUserSegment());
    const [dataSelectors, setDataSelectors] =  useState<DataSelector[]>([]);
    const [segmentFilter, setSegmentFilter] = useState<SegmentationFilterOperation>({
        Filter: 0,
        Condition: 0,
        Values: [],
        SelectorUid: '',
        KeyToFetch: ''
    });
    const [showSubmitButton, setShowSubmitButton] = useState(true);

    useEffect(() => {
        if (isOpen) {
            if (segmentInstance) {
                setPlayerSegment(segmentInstance);
            }

            let bearerToken = "Bearer " + globalData.JWTToken;
            let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector';
            axios.get(url, {headers: {Authorization: bearerToken}})
                .then((response) => {
                    console.log(response.data as DataSelector[]);
                    setDataSelectors(response.data as DataSelector[]);
                });
        }
    }, [isOpen]);

    const resetForm = () => {
        setPlayerSegment(createEmptyUserSegment());
        setDataSelectors([]);
        setSegmentFilter({
            Filter: 0,
            Condition: 0,
            Values: [],
            SelectorUid: '',
            KeyToFetch: ''
        });
    };

    function updatePlayerSegment(playerSegmentField: Partial<PlayerSegment>) {
        setPlayerSegment({...playerSegment, ...playerSegmentField});
    }

    function addFilterOperation(filterOperation: SegmentationFilterOperation) {
        let newFilterOperations = [...playerSegment.filterOperations, filterOperation];
        setPlayerSegment({...playerSegment, ...{filterOperations: newFilterOperations}});
    }

    function removeFilterOperation(index: number) {
        let newFilterOperations = playerSegment.filterOperations.filter((_, i) => i !== index);
        setPlayerSegment({...playerSegment, ...{filterOperations: newFilterOperations}});
    }

    function updateFilterOperation(index: number, filterOperationField: Partial<SegmentationFilterOperation>) {
        console.log("Update Filter Operation", index, filterOperationField);
        if(index === -1)
        {
            setSegmentFilter({...segmentFilter, ...filterOperationField});
            return;
        }
        const filter = {...playerSegment.filterOperations[index], ...filterOperationField};
        let filterOp = playerSegment.filterOperations;
        filterOp[index] = filter;
        setPlayerSegment({...playerSegment, ...{filterOperations: filterOp}});
    }

    function updateFilterCondition(index: number, condition: number): void {
        console.log("Update Filter Condition", index, condition);
        if(index === -1)
        {
            setSegmentFilter({...segmentFilter, ...{Condition: condition as unknown as FilterCondition}});
            return;
        }
        let filterOp = playerSegment.filterOperations;
        filterOp[index].Condition = condition as unknown as FilterCondition;
        setPlayerSegment({...playerSegment, ...{filterOperations: filterOp}});
    }

    const handleSubmit = (event?: React.FormEvent) => {
        if (event) event.preventDefault();
        setShowSubmitButton(false);
        if(segmentInstance) {
            updateSegmentInstanceRequest();
        }
        else {
            createNewSegmentInstanceRequest();
        }
    };

    const createNewSegmentInstanceRequest = () => {
        let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/segment';
        axios.post(url, playerSegment,
            {headers: {Authorization: "Bearer " + globalData.JWTToken}})
            .then((response) => {
                console.log(response);
                setShowSubmitButton(true);
                handleClose();
            });
    }

    const updateSegmentInstanceRequest = () => {
        let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + `/segment/${segmentInstance?.id}`;
        axios.put(url, playerSegment,
            {headers: {Authorization: "Bearer " + globalData.JWTToken}})
            .then((response) => {
                console.log(response);
                setShowSubmitButton(true);
                handleClose();
            });
    }

    const handleClose = () => {
        resetForm();
        onClose();
    }

    const addSegmentFilter = () => {
        addFilterOperation(segmentFilter);
        setSegmentFilter({
            Filter: 0,
            Condition: 0,
            Values: [],
            SelectorUid: '',
            KeyToFetch: ''
        });
    }

    const removeSegmentFilter = (index: number) => {
        removeFilterOperation(index);
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay/>
            <ModalContent  maxW="950px" border={'1px'} borderColor={'gray.700'}>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input value={playerSegment.name} onChange={(event) => updatePlayerSegment({name: event.target.value})} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Input value={playerSegment.description} onChange={(event) => updatePlayerSegment({description: event.target.value})} />
                            </FormControl>
                            {playerSegment.filterOperations.map((playerSegmentFilterOperation, index) => (
                            <HStack spacing={4}>
                                <Select
                                    placeholder="Select Selector"
                                    value={playerSegmentFilterOperation.SelectorUid}
                                    onChange={(e) => updateFilterOperation(index, {SelectorUid: e.target.value})}
                                >
                                    {dataSelectors.map((selector => (
                                        <option key={selector.uid} value={selector.uid}>{selector.name}</option>
                                    )))}
                                </Select>
                                <Select
                                    placeholder="Select Condition 1223"
                                    value={playerSegmentFilterOperation.Condition}
                                    onChange={(e) => updateFilterCondition(index, parseInt(e.target.value)) }
                                >
                                    {Object.entries(FilterCondition)
                                        .filter(([key, condition]) => isNaN(Number(key))) // Filter out numeric keys
                                        .map(([key, condition]) => (
                                            <option key={key} value={condition}>{key}</option>
                                        ))}
                                </Select>
                                <Input
                                    value={playerSegmentFilterOperation.Values.toString()}
                                    onChange={(e) => updateFilterOperation(index, {Values: [e.target.value]})}
                                    placeholder="Filter Value"
                                />
                                <Button width={'350px'} colorScheme="red" onClick={() => removeFilterOperation(index)}>Remove Filter</Button>
                            </HStack>))}
                            <HStack spacing={4}>
                                <Select
                                    placeholder="Select Selector"
                                    value={segmentFilter.SelectorUid}
                                    onChange={(e) => updateFilterOperation(-1, {SelectorUid: e.target.value})}
                                >
                                    {dataSelectors.map((selector => (
                                        <option key={selector.uid} value={selector.uid}>{selector.name}</option>
                                    )))}
                                </Select>
                                <Select
                                    placeholder="Select Condition"
                                    value={segmentFilter.Condition}
                                    onChange={(e) => updateFilterCondition(-1, parseInt(e.target.value))}
                                >
                                    {Object.entries(FilterCondition)
                                        .filter(([key, condition]) => isNaN(Number(key))) // Filter out numeric keys
                                        .map(([key, condition]) => (
                                            <option key={key} value={condition}>{key}</option>
                                        ))}
                                </Select>
                                <Input
                                    value={segmentFilter.Values.toString()}
                                    onChange={(e) => updateFilterOperation(-1, {Values: [e.target.value]})}
                                    placeholder="Filter Value"
                                />
                                <Button width={'350px'} colorScheme="blue" onClick={addSegmentFilter}>Add Filter</Button>
                            </HStack>

                        </Stack>
                        <ModalFooter padding={'20px'}>
                            <Button variant="outline" mr={3} onClick={onClose}>Cancel</Button>
                            {segmentInstance ? (
                                <Button colorScheme="blue" type="submit"> Update </Button>) : (
                            <Button colorScheme="blue" type="submit"> Create </Button>
                            )}
                        </ModalFooter>
                    </ModalBody>
                </form>
            </ModalContent>
        </Modal>
    );
}

export default SegmentModal;
