import * as Yup from 'yup';

const abTestValidationSchema = Yup.object({
    name: Yup.string().required('Experiment Name is required'),
    description: Yup.string().required('Experiment Description is required'),
    platform: Yup.string().required('Platform is required'),
    userTargeting: Yup.number().required('User targeting is required'),
    state: Yup.number().required('State is required'),
    dailyUserLimit: Yup.number()
        .min(1, 'Daily user limit must be set')
        .required(),
    totalMaximumUserCount: Yup.number()
        .min(1, 'Total maximum user count must be set')
        .required(),
    country: Yup.array()
        .of(
            Yup.object({
                value: Yup.string().required(),
                label: Yup.string().required(),
            })
        )
        .nullable()
        .notRequired()
        .test(
            'country-required-if-filter-set',
            'Country is required when countryFilter is set',
            function (this: Yup.TestContext, value): boolean | Yup.ValidationError {
                const { countryFilter } = this.parent;
                console.log("countryFilter: " + countryFilter);
                console.log("value inside country: " + value);
                if(value != null) {
                    console.log("country is not null");
                    if(countryFilter !== 0)
                    {
                        if(value.length === 0) {
                            return this.createError({
                                message: 'Country is required when countryFilter is set',
                            });
                        }
                    }

                    if(countryFilter === 0)
                    {
                        console.log("country filter is 0 returning error");
                        return this.createError({
                            message: 'Country is required when countryFilter is set',
                        });
                    }
                }

                if (countryFilter !== 0) {
                    console.log(value);
                    console.log(JSON.stringify(value));
                    if (Array.isArray(value) && value.length > 0) {
                        return true;
                    } else {
                        return this.createError({
                            message: 'Country is required when countryFilter is set',
                        });
                    }
                }
                return true; // When countryFilter is 0, country can be null or empty
            }
        ),

    countryFilter: Yup.number()
        .notRequired()
        .test(
            'countryFilter-required-if-country-set',
            'Country filter must be set when country is specified',
            function (this: Yup.TestContext, value): boolean | Yup.ValidationError {
                const { country } = this.parent;
                console.log(country);
                console.log(value);
                if(value != null && value !== 0) {
                    if(country === null)
                    {
                        return this.createError({
                            message: 'Country filter must be set when country is specified',
                        });
                    }
                    if(Array.isArray(country) && country.length === 0)
                    {
                        return this.createError({
                            message: 'Country filter must be set when country is specified',
                        });
                    }
                }
                if (Array.isArray(country) && country.length > 0) {
                    if (value !== 0 && value != null) {
                        return true;
                    } else {
                        return this.createError({
                            message: 'Country filter must be set when country is specified',
                        });
                    }
                    }

                return true; // When country is null or empty, countryFilter can be 0 or null
            }
        ),
    versionFilter: Yup.number().notRequired(),
    version: Yup.string()
        .nullable()
        .notRequired()
        .test(
            'version-valid',
            'Version must be a non-empty string or null',
            function (this: Yup.TestContext, value: any): boolean | Yup.ValidationError {
                if (value == null) {
                    return true;
                }
                if (typeof value === 'string' && value.trim() !== '') {
                    return true;
                }
                return this.createError({
                    message: 'Version must be a non-empty string or null',
                });
            }
        )
        .test(
            'version-required-if-filter-set',
            'Version is required when versionFilter is set',
            function (this: Yup.TestContext, value: any): boolean | Yup.ValidationError {
                const { versionFilter } = this.parent;
                if (versionFilter !== 0 && versionFilter != null) {
                    if (typeof value === 'string' && value.trim() !== '') {
                        return true;
                    } else {
                        return this.createError({
                            message: 'Version is required when versionFilter is set',
                        });
                    }
                }
                return true;
            }
        ),
    buildNumber: Yup.number()
        .min(0, 'Build number must be non-negative')
        .notRequired(),
    buildNumberFilter: Yup.number().notRequired(),
    variants: Yup.array()
        .of(
            Yup.object({
                name: Yup.string().required('Variant name is required'),
                values: Yup.array()
                    .of(
                        Yup.object({
                            key: Yup.string().required('RC key is required'),
                            value: Yup.mixed().required('RC value is required'),
                        })
                    )
                    .required(),
            })
        )
        .min(2, 'At least two variants are required')
        .required(),
});

export default abTestValidationSchema;
