import axios from 'axios';
import config from '../config';
import {ABTest, RemoteConfigValue} from '../types';

export const fetchRcNames = async (userToken: string, gameIdentity: string, mainPackageId: number): Promise<RemoteConfigValue[] | null> => {
  try {
    ///game/:bundleId/rc_package/:packageId/rc_values/keys
    const response = await axios.get(`${config.API_ENDPOINT}/game/${gameIdentity}/rc_package/${mainPackageId}/rc_values/keys?valueStringLength=20?onlyUniqueKeys=true`, {
      headers: { Authorization: `Bearer ${userToken}` }
    });
    if (response.data) {
      return response.data as RemoteConfigValue[];
    }
    else{
      return null;
    }
  } catch (error) {
    console.error('Error fetching RC names:', error);
    return null;
  }
};


export async function createExperimentRequest(userToken: string, bundleId: string, payload: ABTest) {
  try {
    const url = `${config.CLOUDFLARE_LOCAL_ENDPOINT}/game/${bundleId}/ab_test`;
    const response = await axios.post(url, payload, { headers: { Authorization: `Bearer ${userToken}` } });
    return response.data;
  } catch (error) {
    console.error('Error creating experiment:', error);
    throw error;
  }
}

export const updateExperiment = async (userToken: string, bundleId: string, payload: ABTest) => {
  try {
    const url = `${config.CLOUDFLARE_LOCAL_ENDPOINT}/game/${bundleId}/ab_test/${payload.id}`;
    const response = await axios.put(url, payload, { headers: { Authorization: `Bearer ${userToken}` } });
    return response.data;
  } catch (error) {
    console.error('Error updating experiment:', error);
    throw error;
  }
};
