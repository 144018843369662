import {
    Button,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    IconButton,
    Grid,
    GridItem,
    HStack,
    VStack,
    Text,
    Flex, Spacer, Tag, Textarea, Select, FormControl
} from '@chakra-ui/react'
import {CloseIcon, EditIcon,  Icon, PlusSquareIcon, ViewIcon} from "@chakra-ui/icons"
import {useDisclosure} from "@chakra-ui/hooks";
import React, { useState, useEffect } from 'react';
import {FaTrashAlt} from 'react-icons/fa';
import {BsFillCheckCircleFill} from 'react-icons/bs';
import {CgCloseO} from 'react-icons/cg';
import axios from 'axios';
import RemoteConfigVariableModal from "./edit_remote_variable_modal";
import DeleteRCKeyModal from "./delete_rc_key_modal";
import config from '../config';
import {formatTimestamp, RemoteConfigTypeToString} from "../utility";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import {ViewFilter} from "./ViewFilter";
import {FilterOperation} from "../types/runtime/filter_operation";
import {RemoteConfigValue} from "../types";
import globalStore, {GlobalStore} from "../store";
import SelectorModal from "../selector/selector_modal";
import SegmentModal from "../segment/segment_modal";
import {FilterType} from "../types/enums/filter_type";
import {FilterCondition} from "../types/enums/filter_condition";


function RemoteConfigData() {
    let globalData = globalStore();

    useEffect(() => {
        const fetchDataAsync = async () => {
            await steamRcData(globalData);
        };

        fetchDataAsync().then(() => {
            console.log("Remote Config Data Fetched");
        });
    }, []);

    return <DisplayRemoteConfigDatas/>;
}

async function steamRcData(globalData: GlobalStore) {
    let lastUpdated = 0; // implement this later
    try {
        while (true) {
            //console.log("Last Updated: " + lastUpdated);
            let bearerToken = "Bearer " + globalData.JWTToken;
            let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/rc_package/stream/' + lastUpdated;
            const response = await axios.get(url, {headers: {Authorization: bearerToken}});
            let incomingRemoteConfigValues = response.data as RemoteConfigValue[];
            //console.log("Remote Config Data: " + JSON.stringify(incomingRemoteConfigValues));
            if(incomingRemoteConfigValues){
                console.log("setting remote config values to incoming data");
                globalData.setRemoteConfigValues(incomingRemoteConfigValues);
                //lastUpdated = getLatestUpdate(incomingRemoteConfigValues);
            }

            // Add a delay to prevent infinite loop
            await new Promise(resolve => setTimeout(resolve, 5000));
        }
    } catch (error) {
        console.error("Error fetching remote config data:", error);
    }
}

function getLatestUpdate(rcValues: RemoteConfigValue[]): number {
    let lastUpdated = 0;
    for (let i = 0; i < rcValues.length; i++) {
        if (rcValues[i].lastUpdated > lastUpdated) {
            lastUpdated = rcValues[i].lastUpdated;
        }
    }
    return lastUpdated;
}

function DisplayRemoteConfigDatas() {
    return (
        <VStack spacing={10} align='stretch'>
            <DisplayRemoteConfigData/>
        </VStack>
    );
}

function DisplayRemoteConfigData()
{
    let globalData = globalStore();
    const addVariableDisclosure = useDisclosure();
    const editRCVariableDisclosure = useDisclosure();
    const deleteRCKeyDisclosure = useDisclosure();
    const [rcVariableData, setRCVariableData] = useState<any>();
    const [deleteRCKeyData, setDeleteRCKeyData] = useState<any>(null);
    const [viewFilter, setViewFilter] = useState(new ViewFilter());

/*    useEffect(() => {
        editRCVariableDisclosure.onOpen();
    },[rcVariableData])*/

    const multiFunction = (disc: { onOpen: () => void }, remoteConfigValue: RemoteConfigValue) => {
        setRCVariableData(remoteConfigValue);
        disc.onOpen();
    };

    return (
        <Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
            <RemoteConfigVariableModal disclosure={editRCVariableDisclosure} remoteData={rcVariableData}/>
            <RemoteConfigVariableModal disclosure={addVariableDisclosure}  remoteData={null}/>

            {deleteRCKeyDisclosure.isOpen && deleteRCKeyData && (
                <DeleteRCKeyModal
                    disclosure={deleteRCKeyDisclosure}
                    rcKey={deleteRCKeyData.name}
                    rcValueId={deleteRCKeyData.id}
                />
            )}

{/*            {editRCVariableDisclosure.isOpen && rcVariableData && (
                <EditRemoteConfigPackageModal
                    disclosure={editRCVariableDisclosure}
                    remoteData={rcVariableData}
                    rcConfigId={configPackageData.id}
                />
            )}*/}

            <VStack  spacing={1}  align='stretch'>
                <HStack width={'100%'}>
                <RemoteConfigHeader addVariableDisclosure={addVariableDisclosure} viewFilter={viewFilter} setViewFilter={setViewFilter}/>
                </HStack>
                <TableContainer>
                    <Table variant='simple' size='md' border={'0px'}>
                        <Thead>
                            <Tr>
                                <Th width="150px">Name</Th>
                                <Th width="200px">Description</Th>
                                <Th width="200px">Value</Th>
                                <Th width="150px">Type</Th>
                                <Th width="150px">Filter</Th>
                                <Th width="200px">Latest Update</Th>
                                <Th width="150px"><Flex justify="center" align="center" width="100%" height="100%">Production</Flex></Th>
                                <Th width="150px"><Flex justify="center" align="center" width="100%" height="100%">Public</Flex></Th>
                                <Th width="150px">Status</Th>
                                <Th width="50px">View</Th>
                                <Th width="50px">Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {globalData.remoteConfigValues && globalData.remoteConfigValues.sort((a: RemoteConfigValue, b: RemoteConfigValue) => a.key.localeCompare(b.key)).map((field: RemoteConfigValue, i: number) => (
                                (viewFilter.canDisplay(field)) ? (
                                    <Tr key={i}>
                                        <Td width="150px">{field.key}</Td>
                                        <Td width={'200px'} maxW={'200px'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" position="relative" _hover={{ overflow: "visible", whiteSpace: "normal" }}>
                                            {field.description}
                                            <Box position="absolute" bg="gray.700" color="white" p="2" borderRadius="md" visibility="hidden" _groupHover={{ visibility: "visible" }} zIndex="tooltip">
                                                {field.description}
                                            </Box>
                                        </Td>
                                        <Td width="200px">
                                            <Text maxW="200px" isTruncated>
                                                {String(field.value)}
                                            </Text>
                                        </Td>
                                        <Td width="150px">{RemoteConfigTypeToString(field.type)}</Td>
                                        <Td width="250px">
                                            {field.filterOperations.length > 0 && (
                                                <Textarea maxW="250px" fontSize={'sm'} border={'0px'}>
                                                    {FilterToString(field.filterOperations)}
                                                </Textarea>
                                            )}
                                        </Td>
                                        <Td width="200px">{formatTimestamp(field.lastUpdated)}</Td>
                                        <Td width="150px">{showCheckMark(!field.isTest)}</Td>
                                        <Td width="150px">{showCheckMark(!field.isPersonal)}</Td>
                                        <Td width="150px">
                                            {field.isActive ? (
                                                <Button onClick={() => toggleRCValueStatus(globalData, field.id)} leftIcon={<Icon as={BsFillCheckCircleFill} color={'green.200'} _hover={{ color: 'gray.300' }} />} variant='simple' />
                                            ) : (
                                                <Button onClick={() => toggleRCValueStatus(globalData, field.id)} leftIcon={<Icon as={CgCloseO} color={'red.200'} _hover={{ color: 'gray.300' }} />} variant='simple' />
                                            )}
                                        </Td>
                                        <Td width="50px">
                                            <ViewButton onOpen={() => multiFunction(editRCVariableDisclosure, field)} />
                                        </Td>
                                        <Td width="50px">
                                            <Button
                                                onClick={() => {
                                                    setDeleteRCKeyData(field);
                                                    deleteRCKeyDisclosure.onOpen();
                                                }}
                                                leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
                                                variant='simple'
                                            />
                                        </Td>
{/*                                        <Td width="50px">
                                            <Button onClick={() => deleteRCValue(userToken, remoteConfigPackageData._id, field._id)} leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />} variant='simple' />
                                        </Td>*/}
                                    </Tr>
                                ) : null
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </Box>
    );
}

function toggleRCValueStatus(globalData: GlobalStore, rcValueId: number): void {
    console.log("toggleRCValueStatus");

    axios.put(config.API_ENDPOINT + '/game/' + globalData.gameId + '/rc_package/1' + '/rc_value/' + rcValueId + '/toggle',
        {},
        {headers: {Authorization: "Bearer " + globalData.JWTToken}})
        .then((response) => {
            const updatedValue = response.data as RemoteConfigValue;
            for(let i = 0; i < globalData.remoteConfigValues.length; i++)
            {
                if(globalData.remoteConfigValues[i].uid === updatedValue.uid)
                {
                    console.log("Updated value: ", updatedValue);
                    globalData.remoteConfigValues[i] = updatedValue;
                }
            }
            globalData.setRemoteConfigValues(globalData.remoteConfigValues);
        });
}

function showCheckMark(isActive: boolean) {
    return (
        <Flex justify="center" align="center" width="100%" height="100%">
            {isActive ? (
                <Icon as={BsFillCheckCircleFill} color={'green.200'} _hover={{ color: 'gray.300' }} />
            ) : (
                <Icon as={CgCloseO} color={'red.200'} _hover={{ color: 'gray.300' }} />
            )}
        </Flex>
    );
}

function FilterToString(filterOperations: FilterOperation[]): string {
    let result = "";
    for (let i = 0; i < filterOperations.length; i++) {
        let filterOperation = filterOperations[i];
        const filterString = FilterType[filterOperation.Filter];
        const conditionString = FilterCondition[filterOperation.Condition];
        let valuesString = "";
        if (filterOperation.Values !== null && filterOperation.Values !== undefined && filterOperation.Values.length !== 0)
            valuesString = filterOperation.Values.join(", ");

        result += `Filter: ${filterString}, Condition: ${conditionString}, Values: [${valuesString}]`;
    }

    return result;
}


function PlusButton({ paddingTop, onOpen }: { paddingTop?: string; onOpen: () => void }) {
    return (
        <IconButton
            onClick={onOpen}
            paddingTop={paddingTop}
            aria-label='Add'
            icon={<PlusSquareIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
            backgroundColor={'transparent'}
            _hover={{ bg: 'transparent', color: 'white' }}
        />
    );
}

function DeleteButton({paddingTop, onOpen}: {paddingTop?: string, onOpen: () => void})
{
    return(
        <IconButton onClick={onOpen} paddingTop={paddingTop}  aria-label='Delete Config' icon={<CloseIcon width={'10px'} color={'gray.500'} _hover={{ color:'gray.300' }}/>} backgroundColor={'transparent'} _hover={{ bg: 'transparent', color:'white' }}/>
    );
}

function EditButton({ onOpen }: { onOpen: () => void }) {
    return (
        <IconButton onClick={onOpen} aria-label='History' icon={<EditIcon color={'gray.500'} _hover={{color: 'gray.300'}}/>}
                    backgroundColor={'transparent'} _hover={{bg: 'transparent', color: 'white'}}/>
    );
}

function ViewButton({onOpen}: {onOpen: () => void}){
    return (
        <IconButton onClick={onOpen} aria-label='Add'
                    icon={<ViewIcon color={'gray.500'} _hover={{color: 'gray.300'}}/>}
                    backgroundColor={'transparent'} _hover={{bg: 'transparent', color: 'white'}}/>
    );
}

function sortVersions(versions: string[]): string[] {
    return versions.sort((a: string, b: string) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aPart = aParts[i] || 0;
            const bPart = bParts[i] || 0;

            if (aPart < bPart) return -1;
            if (aPart > bPart) return 1;
        }

        return 0;
    });
}

function RemoteConfigHeader({
                          addVariableDisclosure,
                          viewFilter,
                          setViewFilter
                      }: {
    addVariableDisclosure: any,
    viewFilter: ViewFilter,
    setViewFilter: React.Dispatch<React.SetStateAction<ViewFilter>>
}) {
    let globalData = globalStore();
    const [filteredCountry, setFilteredCountry] = useState("");
    const [filteredVersion, setFilteredVersion] = useState("");
    const [filteredPlatform, setFilteredPlatform] = useState("");
    const [filteredEnvironment, setFilteredEnvironment] = useState<string>("Select Environment");
    const [filteredViewPrivate, setViewPrivate] = useState<string>("Select Visibility");
    const [availableVersions, setAvailableVersions] = useState<string[]>([]);
    const [availableCountries, setAvailableCountries] = useState<string[]>([]);


    const getUniqueVersions = (remoteConfigValues: RemoteConfigValue[]) => {
        if(remoteConfigValues === null || remoteConfigValues === undefined)
            return [];
        let uniqueVersions: string[] = [];
        let newVersionFound = false;

        remoteConfigValues.forEach((value: RemoteConfigValue) => {
            for (let i = 0; i < value.filterOperations.length; i++) {
                let filterOp = value.filterOperations[i];
                if (filterOp.Filter === 2) { // FilterType.Version
                    if (filterOp.Values !== null && filterOp.Values !== undefined) {
                        for (let i = 0; i < filterOp.Values.length; i++) {
                            if (!uniqueVersions.includes(filterOp.Values[i].toString())) {
                                uniqueVersions.push(filterOp.Values[i].toString());
                                newVersionFound = true;
                            }
                        }
                    }
                }
            }
        });


        if (newVersionFound || uniqueVersions.length !== availableVersions.length) {
            let sortedUnique = sortVersions(uniqueVersions);
            sortedUnique.unshift("Select Version");
            setAvailableVersions(sortedUnique);
        }
    }

    const getUniqueCountries = (rcPackage: RemoteConfigValue[]) => {
        if(rcPackage === null || rcPackage === undefined)
            return [];

        let uniqueCountries: string[] = [];
        let newCountryFound = false;

        rcPackage.forEach((value: RemoteConfigValue) => {
            for (let i = 0; i < value.filterOperations.length; i++) {
                let filterOp = value.filterOperations[i];
                if (filterOp.Filter === 1) { // FilterType.Country
                    if (filterOp.Values !== null && filterOp.Values !== undefined) {
                        for (let i = 0; i < filterOp.Values.length; i++) {
                            if (!uniqueCountries.includes(filterOp.Values[i].toString())) {
                                uniqueCountries.push(filterOp.Values[i].toString());
                                newCountryFound = true;
                            }
                        }
                    }
                }
            }
        });

        if (newCountryFound || uniqueCountries.length !== availableCountries.length) {
            let sortedUnique = uniqueCountries.sort();
            sortedUnique.unshift("Select Country");
            setAvailableCountries(sortedUnique);
        }
    }

    useEffect(() => {
        getUniqueVersions(globalData.remoteConfigValues);
        getUniqueCountries(globalData.remoteConfigValues);
    }, [globalData.remoteConfigValues]);


    useEffect(() => {
        let value: string | null = null;
        if (filteredVersion !== "Select Version") {
            value = filteredVersion;
        }

        setViewFilter((prevViewFilter) => {
            const updatedFilter = new ViewFilter();
            Object.assign(updatedFilter, prevViewFilter);
            updatedFilter.ViewVersion = value;
            return updatedFilter;
        });
    }, [filteredVersion, setViewFilter]);

    useEffect(() => {
        let value: string | null = null;
        if (filteredPlatform !== "Select Platform") {
            value = filteredPlatform.toLowerCase();
        }

        setViewFilter((prevViewFilter: ViewFilter) => {
            const updatedFilter = new ViewFilter();
            Object.assign(updatedFilter, prevViewFilter);
            updatedFilter.ViewPlatform = value;
            return updatedFilter;
        });
    }, [filteredPlatform, setViewFilter]);

    useEffect(() => {
        let isTestEnvironment: boolean | null = null;

        if (filteredEnvironment === "Production") {
            console.log("Production");
            isTestEnvironment = false;
        }

        if(filteredEnvironment === "Test") {
            console.log("Test");
            isTestEnvironment = true;
        }

        setViewFilter((prevViewFilter: ViewFilter) => {
            const updatedFilter = new ViewFilter();
            Object.assign(updatedFilter, prevViewFilter);
            updatedFilter.ViewIsTestEnvironment = isTestEnvironment;
            return updatedFilter;
        });
    }, [filteredEnvironment, setViewFilter]);

    useEffect(() => {
        let isPrivate: boolean | null = null;

        if (filteredViewPrivate === "Public") {
            isPrivate = false;
        }

        if(filteredViewPrivate === "Private") {
            console.log("Test");
            isPrivate = true;
        }

        setViewFilter((prevViewFilter: ViewFilter) => {
            const updatedFilter = new ViewFilter();
            Object.assign(updatedFilter, prevViewFilter);
            updatedFilter.ViewPrivate = isPrivate;
            return updatedFilter;
        });
    }, [filteredViewPrivate, setViewFilter]);

    useEffect(() => {
        let value: string | null = null;
        if (filteredCountry !== "Select Country") {
            value = filteredCountry.toLowerCase();
        }

        setViewFilter((prevViewFilter) => {
            const updatedFilter = new ViewFilter();
            Object.assign(updatedFilter, prevViewFilter);
            updatedFilter.ViewCountry = value;
            return updatedFilter;
        });
    }, [filteredCountry, setViewFilter]);

    return (
        <HStack width='100%' >
            <Box flex={1} h='60px' bg='gray.800' paddingLeft={'10px'} display="flex">
                <HStack spacing='-5px' padding={'5px'} >
                    <strong style={{ width: '100px', display: 'inline-block' }}>Main Config</strong>
                        {( <HStack flex={1}  >
                            <PlusButton onOpen={addVariableDisclosure.onOpen} paddingTop={"2px"}/>
                                <HStack  flex={1}  margin={0}>

                                <Checkbox margin={0} flex="1" minW={'200px'} onChange={() => setViewFilter((prevViewFilter) => {
                                    const updatedFilter = new ViewFilter();
                                    Object.assign(updatedFilter, prevViewFilter);
                                    updatedFilter.ViewOnlyActive= !viewFilter.ViewOnlyActive
                                    return updatedFilter;
                                })  }>View Disabled Keys</Checkbox>

                                    <FormControl flex="1" minW={'200px'}>
                                        <Select
                                            value={filteredPlatform}
                                            onChange={(e) => setFilteredPlatform(e.target.value)}
                                        >
                                            {["Select Platform", "iOS", "Android"].map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Checkbox paddingRight={'15px'}  onChange={() => setViewFilter((prevViewFilter) => {
                                        const updatedFilter = new ViewFilter();
                                        Object.assign(updatedFilter, prevViewFilter);
                                        updatedFilter.ExclusivePlatform= !viewFilter.ExclusivePlatform
                                        return updatedFilter;
                                    })  }>Excl.</Checkbox>

                                <FormControl flex="1" minW={'200px'}>
                                    <Select
                                        value={filteredVersion}
                                        onChange={(e) => setFilteredVersion(e.target.value)}
                                    >
                                        {availableVersions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                    <Checkbox paddingRight={'15px'}  onChange={() => setViewFilter((prevViewFilter) => {
                                        const updatedFilter = new ViewFilter();
                                        Object.assign(updatedFilter, prevViewFilter);
                                        updatedFilter.ExclusiveVersion= !viewFilter.ExclusiveVersion
                                        return updatedFilter;
                                    })  }>Excl.</Checkbox>

                                    <FormControl flex="1" minW={'200px'}>
                                        <Select
                                            value={filteredCountry}
                                            onChange={(e) => setFilteredCountry(e.target.value)}
                                        >
                                            {availableCountries.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Checkbox paddingRight={'15px'} onChange={() => setViewFilter((prevViewFilter) => {
                                        const updatedFilter = new ViewFilter();
                                        Object.assign(updatedFilter, prevViewFilter);
                                        updatedFilter.ExclusiveCountry= !viewFilter.ExclusiveCountry
                                        return updatedFilter;
                                    })  }>Excl.</Checkbox>

                                    <FormControl flex="1" minW={'200px'}>
                                        <Select
                                            value={filteredEnvironment}
                                            placeholder="Select Environment"
                                            onChange={(e) => setFilteredEnvironment(e.target.value)}
                                        >
                                            <option key={"Production"} value={"Production"}>Production</option>
                                            <option key={"Test"} value={"Test"}>Test</option>
                                        </Select>
                                    </FormControl>

                                    <FormControl flex="1" minW={'200px'}>
                                        <Select
                                            value={filteredViewPrivate}
                                            placeholder="Select Visibility"
                                            onChange={(e) => setViewPrivate(e.target.value)}
                                        >
                                            <option key={"Private"} value={"Private"}>Private</option>
                                            <option key={"Public"} value={"Public"}>Public</option>
                                        </Select>
                                    </FormControl>
                                </HStack>
                            </HStack>
                        )}
                </HStack>
            </Box>
        </HStack>

    );
}

function RemoteConfig() {
    const createSelector = useDisclosure();
    const createSegment = useDisclosure();
    const editSegment = useDisclosure();

    return (
        <Grid
            height={'1px'}
            width={'100%'}
            templateRows='repeat(4, 1fr)'
            templateColumns='repeat(5, 1fr)'
            gap={2}
            paddingLeft={'12px'}
            paddingRight={'10px'}
        >
            <GridItem colSpan={5} padding={'15px'}>
                <SelectorModal isOpen={createSelector.isOpen} onClose={createSelector.onClose}/>
                <SegmentModal title={"Create Segment"} isOpen={createSegment.isOpen} onClose={createSegment.onClose}/>
                <SegmentModal title={"Edit Segment"} isOpen={editSegment.isOpen} onClose={editSegment.onClose}/>
            </GridItem>
            <GridItem paddingLeft={'20px'} colSpan={5}>
                <RemoteConfigData/>
            </GridItem>
        </Grid>
    );
}


export default RemoteConfig;
