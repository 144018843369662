import React from 'react';
import {
    Button,
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Select,
    Stack,
    Textarea,
    useToast,
    VStack,
} from '@chakra-ui/react';
import {LuTestTube2} from "react-icons/lu";
import {FaTrashAlt} from 'react-icons/fa';
import {Field, FieldArray} from 'formik';
import {ABTestVariant, RCType, RemoteConfigValue} from "../../types";
import {GlobalStore} from "../../store";
import {Platform} from "../../types/enums/platform";

export function Variants({dataStore, rcValues}: {dataStore: GlobalStore, rcValues: Array<RemoteConfigValue>}) {

    const { abTestModal, updateAbTestModal, updateAbTestModalVariant, deleteABTestModalVariant, updateAbTestModalVariantRCValue } = dataStore;
    const toast = useToast();

    if(!abTestModal) {
        return null;
    }

    const addVariableToVariants = (variableKey: string) => {
        if(dataStore.abTestModal === null) {
            console.error("abTestModal is null");
            return;
        }
        const newValue: RemoteConfigValue = {
            abTestUid: '',
            description: "",
            filterOperations: [],
            id: 0,
            isActive: true,
            isDeleted: false,
            isPersonal: false,
            isOverWritten: false,
            isTest: false,
            lastUpdated: 0,
            packageId: 1,
            platform: Platform.All,
            type: RCType.Integer,
            userId: 0,
            variantId: null,
            key: variableKey,
            uid: '',
            value: ''
        };

        for(let i = 0; i < dataStore.abTestModal.variants.length; i++) {
            const variantValues = dataStore.abTestModal.variants[i].values;
            for(let j = 0; j < variantValues.length; j++) {
                if (variableKey === variantValues[j].key) {
                    console.log("Variable already exists");
                    return;
                }
            }
        }

        for(let i = 0; i < dataStore.abTestModal.variants.length; i++) {
            dataStore.abTestModal.variants[i].values.push(newValue);
        }

        const newVariants = [...dataStore.abTestModal.variants];
        updateAbTestModal({variants: newVariants});
        console.log(JSON.stringify(abTestModal));
    };

    const deleteVariableFromVariants = (variableKey: string) => {
        if(dataStore.abTestModal === null) {
            console.error("abTestModal is null");
            return;
        }

        for(let i = 0; i < dataStore.abTestModal.variants.length; i++) {
            const variantValues = dataStore.abTestModal.variants[i].values;
            dataStore.abTestModal.variants[i].values = variantValues.filter((value) => value.key !== variableKey);
        }

        const newVariants = [...dataStore.abTestModal.variants];
        updateAbTestModal({variants: newVariants});
    };

    const addToVariants = () => {
        console.log("Adding variant");
        let variant: ABTestVariant = { name: 'Variant ' + (abTestModal.variants.length +  1), uuid: '', values: abTestModal.variants[0].values};
        for(let i = 0; i < variant.values.length; i++) {
            variant.values[i].value = '';
        }
        let variantExists = abTestModal.variants.some((v) => v.name === variant.name);
        if(variantExists) {
            console.log("Variant name already exists");
            toast({
                title: 'Variant name already exists: ' + variant.name,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            }
        else
        {
            updateAbTestModal({variants: [...abTestModal.variants, variant]});
        }

    };

    return (
        <FieldArray
            name="variants"
            render={arrayHelpers => (
                <FormControl mt={4} border={'0px'}>
                    {abTestModal.variants.map((variant, index) => (
                        <Stack key={index} paddingBottom={'50px'}>
                            <HStack>
                                <Field name={`variants.${index}.Name`}>
                                    {({ field, form }: { field: any; form: any }) => (
                                        <FormControl isInvalid={form.errors.variants?.[index]?.Name && form.touched.variants?.[index]?.Name}>
                                            <Input
                                                {...field}
                                                border={'0px'}
                                                fontStyle={'bold'}
                                                value={variant.name}
                                                onChange={(e) => {
                                                    console.log("Setting value: " + e.target.value);
                                                    updateAbTestModalVariant(index, {name: e.target.value});
                                                    field.onChange(e);
                                                    if(dataStore.abTestModal) {
                                                        let arraySize = dataStore.abTestModal?.variants[index].values.length;
                                                        form.setFieldValue(`variants.${index}.values.${-1}.Value`, e.target.value);
                                                    }
                                                }}
                                            />
                                            <FormErrorMessage>{form.errors.variants?.[index]?.Name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Button
                                    rightIcon={<FaTrashAlt />}
                                    backgroundColor="transparent"
                                    _hover={{ bg: 'transparent' }}
                                    onClick={() => deleteABTestModalVariant(index)}
                                />
                            </HStack>
                            {index === 0 && (
                                <Select
                                    value=""
                                    onChange={(e) => {
                                        console.log("addVariableToVariants: " + e.target.value);
                                        addVariableToVariants(e.target.value);
                                    }}
                                >
                                    <option value="">Select a variable</option>
                                    {rcValues.map((remoteConfigValue) => (
                                        <option key={remoteConfigValue.key} value={remoteConfigValue.key}>
                                            {remoteConfigValue.key}
                                        </option>
                                    ))}
                                </Select>
                            )}

                            <VStack direction="row" mt={2} paddingTop={'15px'}>
                                {variant.values.map((variable, rcIndex) => (
                                    <Field key={variable.key} name={`variants.${index}.RcValues.${rcIndex}.Value`}>
                                        {({ field, form }: { field: any; form: any }) => (
                                            <FormControl isInvalid={form.errors.variants?.[index]?.RcValues?.[rcIndex]?.Value && form.touched.variants?.[index]?.RcValues?.[rcIndex]?.Value}>
                                                <InputGroup>
                                                    <InputLeftAddon minWidth={'200px'}>
                                                        {variable.key}
                                                    </InputLeftAddon>
                                                    <Textarea
                                                        {...field}
                                                        resize='None'
                                                        size='sm'
                                                        value={variable.value}
                                                        onChange={(e) => {
                                                            console.log("Setting value: " + e.target.value);
                                                            form.setFieldValue(`variants.${index}.RcValues.${rcIndex}.Value`, e.target.value);
                                                            updateAbTestModalVariantRCValue(index, rcIndex, {value: e.target.value});
                                                            field.onChange(e);
                                                            console.log(JSON.stringify(dataStore.abTestModal));
                                                        }}
                                                    />
                                                    <InputRightAddon>
                                                        <Button
                                                            rightIcon={<FaTrashAlt />}
                                                            backgroundColor="transparent"
                                                            _hover={{ bg: 'transparent' }}
                                                            onClick={() => deleteVariableFromVariants(variable.key)}
                                                        />
                                                    </InputRightAddon>
                                                </InputGroup>
                                                <FormErrorMessage>{form.errors.variants?.[index]?.RcValues?.[rcIndex]?.Value}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                ))}
                            </VStack>
                            {(abTestModal?.variants) && (index === abTestModal.variants.length - 1) && (
                                <Button
                                    rightIcon={<LuTestTube2/>}
                                    colorScheme='blue'
                                    variant='outline'
                                    marginTop={'50px'}
                                    onClick={() => addToVariants()}
                                >
                                    Add Variant
                                </Button>
                            )}
                        </Stack>
                    ))}
                </FormControl>
            )}
        />
    );
};

export default Variants;
