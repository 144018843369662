export enum FilterType {
    Country = 1,
    Version = 2,
    DeviceId = 3,
    Platform = 4,
    Date = 5,
    BuildNumber = 6,
    NewUser = 7,
    AbTest = 8,
    PlayFab = 9,
    PlayFabCustom = 10,
    Segmentation = 11
}

export enum FilterTypeString {
    Country = 'Country',
    Version = 'Version',
    DeviceId = 'DeviceId',
    Platform = 'Platform',
    Date = 'Date',
    BuildNumber = 'BuildNumber',
    NewUser = 'NewUser',
    AbTest = 'AbTest'
}
