import React, {useEffect, useState} from 'react';
import {
    Button,
    FormControl,
    FormLabel, HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Select, Spinner,
    Stack,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from "../store";
import {DataSelector} from "../types/segment";
import {DataSource} from "../types/enums/data_source";


const createEmptyDataSelector = (): DataSelector =>  {
    return {
        id: 0,
        creationDate: 0,
        isDeleted: false,
        isOverWritten: false,
        lastUpdated: 0,
        uid: "",
        userId: 0,
        name: '',
        description: '',
        dataSource: DataSource.Playfab,
        fieldName: ''
    }
};



export function SelectorModal({isOpen, onClose, selectorInstance}: {isOpen: boolean; onClose: () => void; selectorInstance?: DataSelector}) {
    let globalData = globalStore();
    const [dataSelector, setDataSelector] = useState<DataSelector>(createEmptyDataSelector());
    const [showSubmitButton, setShowSubmitButton] = useState(true);
    const [playfabUserId, setPlayfabUserId] = useState('');
    const [playfabUserData, setPlayfabUserData] = useState<string[]>([]);
    const [isFetching, setIsFetching] = useState(false);


    useEffect(() => {
        if (selectorInstance) {
            setDataSelector(selectorInstance);
        }
    }, [selectorInstance]);

    const resetForm = () => {
        setDataSelector(createEmptyDataSelector());
    };

    function updateDataSelector(dataSelectorField: Partial<DataSelector>) {
        setDataSelector({...dataSelector, ...dataSelectorField});
    }

    const handleCreateSelector = (event?: React.FormEvent) => {
        if (event) event.preventDefault();
        setShowSubmitButton(false);
        let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector';
        axios.post(url, dataSelector,
            {headers: {Authorization: "Bearer " + globalData.JWTToken}})
            .then((response) => {
                console.log(response);
                resetForm();
                setShowSubmitButton(true);
                onClose();
            });
    };

    const handleUpdateSelector = (selectorId : number) => {
        setShowSubmitButton(false);
        let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector/' + selectorId;
        axios.put(url, dataSelector,
            {headers: {Authorization: "Bearer " + globalData.JWTToken}})
            .then((response) => {
                console.log(response);
                resetForm();
                setShowSubmitButton(true);
                onClose();
            });
    };

    const processSubmission = () => {
        if (selectorInstance) {
            handleUpdateSelector(dataSelector.id);
        } else {
            handleCreateSelector();
        }
    }

    const onPlayfabDataFetch = () => {
        setIsFetching(true);
        let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/playfab_data/' + playfabUserId;
        axios.get(url, {headers: {Authorization: "Bearer " + globalData.JWTToken}})
            .then((response) => {
                const extractedKeys = Object.keys(response.data);
                setPlayfabUserData(extractedKeys);
            }).finally(() => setIsFetching(false));
        }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                {selectorInstance ? (
                    <ModalHeader>Update Selector</ModalHeader>
                ) : (
                <ModalHeader>Create Selector</ModalHeader>
                )}
                <ModalCloseButton />
                <form onSubmit={processSubmission}>
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input value={dataSelector.name} onChange={(event) => updateDataSelector({name: event.target.value})} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Input value={dataSelector.description} onChange={(event) => updateDataSelector({description: event.target.value})} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Template Playfab Id</FormLabel>
                                <HStack>
                                <Input value={playfabUserId} onChange={(event) => setPlayfabUserId(event.target.value)} />
                                    {isFetching ? (
                                        <Spinner />
                                    ) : (
                                        <Button onClick={onPlayfabDataFetch}>Fetch</Button>
                                    )}
                                </HStack>
                            </FormControl>
                            <FormControl flex="1" minW={'200px'}>
                                <Select
                                    value={dataSelector.fieldName}
                                    onChange={(e) => updateDataSelector({fieldName: e.target.value})}
                                >
                                    {playfabUserData.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                         <FormControl>
                                <FormLabel>Selected FieldName</FormLabel>
                                <Input value={dataSelector.fieldName} onChange={(event) => updateDataSelector({fieldName: event.target.value})} />
                            </FormControl>
                        </Stack>
                        <ModalFooter padding={'20px'}>
                            <Button variant="outline" mr={3} onClick={onClose}>Cancel</Button>
                            {showSubmitButton ? (
                                selectorInstance ? (
                                <Button colorScheme="blue" type="submit"> Update </Button>
                            ) : (
                                <Button colorScheme="blue" type="submit"> Create</Button>
                            ))
                            : (
                            <Spinner />
                            )}
                        </ModalFooter>
                    </ModalBody>
                </form>
            </ModalContent>
        </Modal>
    );
}

export default SelectorModal;
