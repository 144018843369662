import React, {useEffect, useRef} from 'react';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    FormHelperText,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Stack,
    Textarea
} from '@chakra-ui/react';
import axios from "axios";
import _ from 'lodash';
import {formatFilters, formatValue, populateFilters} from "../utility";
import config from '../config';
import globalStore from "../store";
import {RCType, RemoteConfigValue} from "../types";
import {FilterCondition} from "../types/enums/filter_condition";
import {Select as ChakraReactSelect} from "chakra-react-select";
import {Countries} from "./enums";
import {Platform} from "../types/enums/platform";
import {PlayerSegment} from "../types/segment";
import {FilterType} from "../types/enums/filter_type";
import {FilterOperation} from "../types/runtime/filter_operation";

// The initial state for a new filter
const countryList = Countries.map((country) => ({ value: country.code, label: country.name }));

export function RemoteConfigVariableModal({disclosure, remoteData}: {
    disclosure: { isOpen: boolean; onClose: () => void };
    remoteData: RemoteConfigValue | null;
}) {
    const globalData = globalStore();
    const [segments, setSegments] = React.useState<PlayerSegment[]>([]);
    const [segmentNames, setSegmentNames] = React.useState<any[]>([]);
    const [remoteConfigValue, setRemoteConfigValue] = React.useState<RemoteConfigValue>();
    const hasFetchedData = useRef(false);
    const [title, setTitle] = React.useState<string>("Create Remote Config Variable");
    const [updatingExistingVariable, setUpdatingExistingVariable] = React.useState<boolean>(false);
    const [isJsonValid, setIsJsonValid] = React.useState<boolean>(true);
    const [isJsonValueEntered, setIsJsonValueEntered] = React.useState<boolean>(false);
    const [jsonError, setJsonError] = React.useState<string>('');

    const validationSchema = Yup.object({
        variableKey: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        variableType: Yup.string().required('Required'),
        variableValue: Yup.string().required('Required'),
        filters: Yup.array().of(Yup.object({
            Filter: Yup.string(),
            Condition: Yup.string(),
            Values: Yup.string()
        }))
    });

    const validateJson = (value: string) => {
        if (value.startsWith('{'))  {
            setIsJsonValueEntered(true);
            try {
                JSON.parse(value);
                setIsJsonValid(true);
                setJsonError("");
            } catch (e) {
                setIsJsonValid(false);
                setJsonError((e as Error).message);
            }
        } else {
            setIsJsonValueEntered(false);
            setIsJsonValid(true);
            setJsonError("");
        }
    };

    useEffect(() => {
            const fetchData = async () => {
                if (disclosure.isOpen && !hasFetchedData.current) {
                    hasFetchedData.current = true;
                    if (remoteData) {
                        if(remoteData.type === RCType.String) {
                            const result = await fetchFullRemoteConfig(remoteData.id);
                            if(result) {
                                remoteData = result;
                            }
                        }
                        console.log('Remote data:', remoteData);
                        let clonedRemoteData = _.cloneDeep(remoteData);
                        setTitle("Edit Remote Config Variable");
                        setUpdatingExistingVariable(true);
                        clonedRemoteData.filterOperations.push({
                            Filter: FilterType.Version,
                            Condition: FilterCondition.NoOp,
                            Values: []
                        });
                        setRemoteConfigValue(clonedRemoteData);
                    } else {
                        setRemoteConfigValue({
                            abTestUid: null,
                            isActive: false,
                            isDeleted: false,
                            isOverWritten: false,
                            packageId: 1,
                            isPersonal: true,
                            userId: 0,
                            variantId: null,
                            id: 0,
                            uid: '',
                            key: '',
                            description: '',
                            type: RCType.String,
                            value: '',
                            isTest: true,
                            platform: Platform.All,
                            filterOperations: [{
                                Filter: FilterType.Version,
                                Condition: FilterCondition.NoOp,
                                Values: []
                            }],
                            lastUpdated: 0
                        });
                    }

                    let bearerToken = "Bearer " + globalData.JWTToken;
                    let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/segment';
                    axios.get(url, {headers: {Authorization: bearerToken}})
                        .then((response) => {
                            console.log(response.data as PlayerSegment[]);
                            setSegments(response.data as PlayerSegment[]);
                            setSegmentNames(extractSegmentNames(response.data as PlayerSegment[]));
                        });
                }
            };

            fetchData();

            return () => {
                if (!disclosure.isOpen) {
                    setTitle("Create Remote Config Variable");
                    setUpdatingExistingVariable(false);
                    setRemoteConfigValue(undefined);
                    setSegments([]);
                    setSegmentNames([]);
                    hasFetchedData.current = false;
                }
            };

    }, [disclosure.isOpen, remoteData]);


    const extractSegmentNames = (segments: PlayerSegment[]): any[] => {
        return segments.map(segment => ({ value: segment.uid, label: segment.name }));
    }

    function updateRemoteConfigValue(updatedValue: Partial<RemoteConfigValue>) {
        //Todo: This is a hack to set boolean value to true if it is empty
        if(updatedValue.type === RCType.Boolean && remoteConfigValue?.value === '') {
            updatedValue.value = true;
        }

        setRemoteConfigValue((prev) => prev ? { ...prev, ...updatedValue} : undefined);
    }

    function onEnvironmentChange(envValue: string) {
        if (envValue === 'development') {
            updateRemoteConfigValue({ isTest: true });
        } else if (envValue === 'production') {
            updateRemoteConfigValue({ isTest: false });
        } else {
            throw new Error('Invalid environment value');
        }
    }

    function onPrivacyModeChange(envValue: string) {
        if (envValue === 'isPersonal') {
            updateRemoteConfigValue({ isPersonal: true });
        } else if (envValue === 'isPublic') {
            updateRemoteConfigValue({ isPersonal: false });
        } else {
            throw new Error('Invalid environment value');
        }
    }

    function updateCountryFilter(updatedValue: FilterOperation) {
        let countryFilter = remoteConfigValue?.filterOperations.find((filter) => filter.Filter === FilterType.Country);
        if (countryFilter && remoteConfigValue) {
            //remove existing country filter
            let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.Country);
            setRemoteConfigValue((prev) => prev ? { ...prev, filterOperations: updatedFilters } : undefined);
        }

        updatedValue.Filter = FilterType.Country;
        setRemoteConfigValue((prev) => prev ? { ...prev, filterOperations: [...prev.filterOperations, updatedValue] } : undefined);
    }

    function getCountryFilter(): FilterOperation {
        if (remoteConfigValue) {
            const result = remoteConfigValue.filterOperations.find((filter) => filter.Filter === FilterType.Country) as FilterOperation;
            if (result) {
                console.log(remoteConfigValue);
                return result;
            }
        }
        return {Filter: FilterType.Country, Condition: FilterCondition.NoOp, Values: []};
    }

    type multiSelection = {
        value: string,
        label: string
    }

    function getSegmentFilter(): FilterOperation {
        if(remoteConfigValue) {
            const result = remoteConfigValue.filterOperations.find((filter) => filter.Filter === FilterType.Segmentation) as FilterOperation;
            if(result) {
                return result;
            }
        }
        return {Filter: FilterType.Segmentation, Condition: FilterCondition.NoOp, Values: []};
    }

    function updateSegmentCondition(condition: FilterCondition) {
        let segmentFilter = getSegmentFilter();
        segmentFilter.Condition = condition;
        segmentFilter.Filter = FilterType.Segmentation;
        if(remoteConfigValue){
            console.log("updateSegmentCondition");
            console.log("condition: " + condition);
            let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.Segmentation);
            setRemoteConfigValue((prev) => prev ? { ...prev, filterOperations: [...updatedFilters, segmentFilter] } : undefined);
        }
    }

    function updateSegmentFilter(selection: multiSelection[]) {
        console.log("updateSegmentFilter");
        console.log(selection);
        let segmentFilter = getSegmentFilter();
        segmentFilter.Values = selection.map((segment) => segment.value);
        segmentFilter.Filter = FilterType.Segmentation;
        if (remoteConfigValue) {
            let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.Segmentation);

            setRemoteConfigValue((prev) => prev ? {
                ...prev,
                filterOperations: [...updatedFilters, segmentFilter]
            } : undefined);
        }
    }

    function getSelectedSegments(): multiSelection[] {
        //get country filter values and generate country selection from countryList
        let segmentFilter = getSegmentFilter();
        let selectedSegments: multiSelection[] = [];
        if(segmentFilter.Values) {
            selectedSegments = segmentFilter.Values.map((segment) => {
                return { value: segment as string, label: getSegmentNameFromUid(segment as string) };
            });
        }
        return selectedSegments
    }

    function getSegmentNameFromUid(uid: string): string {
        let segment = segments.find((segment) => segment.uid === uid);
        if(segment) {
            return segment.name;
        }

        return "" ;
    }


    function getSelectedCountries(): multiSelection[] {
        //get country filter values and generate country selection from countryList
        let countryFilter = getCountryFilter();
        let selectedCountries: multiSelection[] = [];
        if(countryFilter.Values) {
            selectedCountries = countryFilter.Values.map((country) => {
                return { value: country as string, label: country as string };
            });
        }
        return selectedCountries;
    }



    function updateCountrySelection(selectedCountries: multiSelection[]) {
        let countryFilter = getCountryFilter();
        countryFilter.Values = selectedCountries.map((country) => country.value);
        updateCountryFilter(countryFilter);
        console.log(selectedCountries);
    }

    function getVersionFilters(): FilterOperation[] {
        if(remoteConfigValue) {
            return remoteConfigValue.filterOperations.filter((filter) => filter.Filter === FilterType.Version);
        }
        return [];
    }

    function updateVersionFilterCondition(index: number, filterCondition: FilterCondition) {
        console.log("updateVersionFilter");
        console.log(index);
        if(remoteConfigValue) {
            const versionFilters = remoteConfigValue.filterOperations.filter(filter => filter.Filter === FilterType.Version).map((filter, i) => {
                if(i === index) {
                    return { ...filter, ...{ Condition: filterCondition } };
                }
                return filter;
            });

            let updatedFilters = remoteConfigValue.filterOperations.filter(filter => filter.Filter !== FilterType.Version);
            updatedFilters.push(...versionFilters);

            setRemoteConfigValue((prev) => prev ? { ...prev, filterOperations: updatedFilters } : undefined);
        }
    }

    function updateVersionFilterValue(index: number, value: string) {
        console.log("updateVersionFilterValue");
        console.log(index);
        console.log(value);
        //remote empty spaces
        value = value.trim();
        console.log(value);

        if(remoteConfigValue) {
            const updatedVersionFilters = remoteConfigValue.filterOperations.filter(filter => filter.Filter === FilterType.Version).map((filter, i) => {
                if(i === index) {
                    console.log("updateVersionFilterValue: " + value + " with index: " + index);
                    return { ...filter, ...{Values: [value]} };
                }
                return filter;
            });

            let updatedFilters = remoteConfigValue.filterOperations.filter(filter => filter.Filter !== FilterType.Version);
            updatedFilters.push(...updatedVersionFilters);

            console.log(updatedFilters);

            setRemoteConfigValue((prev) => prev ? { ...prev, filterOperations: updatedFilters } : undefined);
        }
    }

    function getVersionFilterValue(filterOperation: FilterOperation): string {
        //console.log("getVersionFilterValue");
        //console.log(filterOperation);
        if (filterOperation !== undefined && filterOperation.Values && filterOperation.Values.length > 0) {
            const val  = filterOperation.Values.join(',');
            //console.log("getVersionFilterValue: " + val);
            //console.log(filterOperation);
            return val;
        }
        return '';
    }


    function addVersionFilter() {
        if (remoteConfigValue) {
            remoteConfigValue.filterOperations.push({
                Filter: FilterType.Version,
                Condition: FilterCondition.NoOp,
                Values: []
            });
            setRemoteConfigValue((prev) => prev ? {...prev} : undefined);
        }
    }

    function removeVersionFilter(index: number) {
        if (remoteConfigValue) {
            const updatedVersionFilters = remoteConfigValue.filterOperations.filter(filter => filter.Filter === FilterType.Version);
            const updatedFilters = updatedVersionFilters.filter((filter, i) => i!== index);

            const otherFilters = remoteConfigValue.filterOperations.filter(filter => filter.Filter !== FilterType.Version);

            setRemoteConfigValue((prev) => prev? {...prev, filterOperations: [...otherFilters, ...updatedFilters] } : undefined);
        }
    }

    async function fetchFullRemoteConfig(remoteConfigId: number): Promise<RemoteConfigValue | null> {
        return axios.get(
            `${config.API_ENDPOINT}/game/${globalData.gameId}/rc_package/1/rc_value/${remoteConfigId}`,
            {headers: {Authorization: `Bearer ${globalData.JWTToken}`}}
        )
            .then((response) => {
                return response.data as RemoteConfigValue;
            })
            .catch((error) => {
                console.error("Error fetching full remote config:", error);
                if (error.response) {
                    console.error("Response data:", error.response.data);
                    console.error("Response status:", error.response.status);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Error setting up request:", error.message);
                }
                return null;
            });
    }

    function sendUpdateRequest(data: RemoteConfigValue | undefined) {
        console.log("Inside sendUpdateRequest");
        console.log(data);
        return axios.put(
            `${config.API_ENDPOINT}/game/${globalData.gameId}/rc_package/1/rc_value/${remoteConfigValue?.id}`,
            data,
            { headers: { Authorization: `Bearer ${globalData.JWTToken}` } }
        )
    }

    function sendCreateRequest(data: RemoteConfigValue | undefined) {
        console.log("Inside sendCreateRequest");
        console.log(data);
        return axios.post(
            `${config.API_ENDPOINT}/game/${globalData.gameId}/rc_package/1/rc_value`,
            data,
            { headers: { Authorization: `Bearer ${globalData.JWTToken}` } }
        )
    }

    function remoteEmptyVersionFilters(remoteConfigValue: RemoteConfigValue | undefined) {
        if (remoteConfigValue) {
            //filter version filters that are empty and remove them
            let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => {
                if (filter.Filter === FilterType.Version) {
                    return filter.Values.length > 0 && filter.Condition !== FilterCondition.NoOp;
                }
                return true;
            });



            //setRemoteConfigValue((prev) => prev ? {...prev, filterOperations: updatedFilters} : undefined);
            return { ...remoteConfigValue, filterOperations: updatedFilters };
        }
        return undefined;
    }

    return (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent maxW="950px">
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <Formik
                    initialValues={{
                        variableKey: remoteConfigValue?.key,
                        description: remoteConfigValue?.description,
                        variableType: remoteConfigValue?.type,
                        variableValue: remoteConfigValue?.value,
                        filters: populateFilters(remoteConfigValue?.filterOperations)
                    }}
                    //validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log("Inside onSubmit");
                        const updatedRemoteConfigValue = remoteEmptyVersionFilters(remoteConfigValue);

                        console.log("updatedRemoteConfigValue");
                        console.log(updatedRemoteConfigValue);

                        if (!updatedRemoteConfigValue) {
                            console.error("Failed to update remote config value");
                            setSubmitting(false);
                            return;
                        }

                        setRemoteConfigValue(updatedRemoteConfigValue);

                        let req = null;

                        if(updatingExistingVariable) {
                            req = sendUpdateRequest(updatedRemoteConfigValue);
                        }else
                        {
                            req = sendCreateRequest(updatedRemoteConfigValue);
                        }

                        req.then((response) => {
                            const updatedRcValue = response.data as RemoteConfigValue;
                            const updatedValues = globalData.remoteConfigValues.map(val =>
                                val.uid === remoteConfigValue?.uid ? updatedRcValue : val
                            );
                            globalData.setRemoteConfigValues(updatedValues);
                            disclosure.onClose();
                        })
                        .finally(() => {
                            setRemoteConfigValue(undefined);
                            hasFetchedData.current = false;
                            setSubmitting(false);
                        });
                    }}
                >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form>
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Field
                                    as={Input}
                                    name="variableKey"
                                    placeholder="Variable Key"
                                    value={remoteConfigValue?.key}
                                    onChange={(e: any) => {
                                        updateRemoteConfigValue({key: e.target.value});
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Field
                                    as={Textarea}
                                    name="description"
                                    placeholder="Description"
                                    value={remoteConfigValue?.description}
                                    onChange={(e: any) => {
                                        updateRemoteConfigValue({description: e.target.value});
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Type</FormLabel>
                                <Field
                                    as={Select}
                                    name="variableType"
                                    placeholder="Select Type"
                                    value={remoteConfigValue?.type}
                                    onChange={(e: any) => {
                                        updateRemoteConfigValue({type: parseInt(e.target.value)});
                                    }}
                                >
                                    <option value={RCType.Integer}>Integer</option>
                                    <option value={RCType.Float}>Float</option>
                                    <option value={RCType.Boolean}>Boolean</option>
                                    <option value={RCType.String}>String</option>
                                </Field>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Value</FormLabel>
                                {remoteConfigValue?.type === RCType.Boolean ? (
                                    <Field
                                        as={Select}
                                        name="variableValue"
                                        value={remoteConfigValue.value.toString()}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            updateRemoteConfigValue({value: e.target.value === 'true'});
                                        }}
                                    >
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </Field>
                                ) : remoteConfigValue?.type === RCType.Integer ? (
                                    <NumberInput
                                        value={remoteConfigValue.value}
                                        onChange={(valueString, valueNumber) => {
                                            if (!isNaN(valueNumber)) {
                                                updateRemoteConfigValue({value: Math.floor(valueNumber)});
                                            }
                                        }}
                                        precision={0}
                                    >
                                        <NumberInputField name="variableValue" />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                ) : remoteConfigValue?.type === RCType.Float ? (
                                    <NumberInput
                                        value={remoteConfigValue.value}
                                        onChange={(valueString, valueNumber) => {
                                            updateRemoteConfigValue({value: valueNumber});
                                        }}
                                        precision={9}
                                        step={0.0000001}
                                    >
                                        <NumberInputField name="variableValue" />
                                    </NumberInput>
                                ) : (
                                    <Field
                                        as={Textarea}
                                        name="variableValue"
                                        placeholder="Value"
                                        value={remoteConfigValue?.value}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            validateJson(e.target.value);
                                            updateRemoteConfigValue({value: e.target.value});
                                        }}
                                        backgroundColor={!remoteConfigValue?.value ? undefined : isJsonValueEntered && (isJsonValid ? "green.700" : "red.700")} 
                                    />
                                )}
                                {!remoteConfigValue?.value ? undefined : remoteConfigValue?.type === RCType.String && isJsonValueEntered && !isJsonValid && <FormHelperText color="red.500">{jsonError}</FormHelperText>}
                            </FormControl>
                            <Field name="privacy_mode">
                                {({ field, form }: { field: any; form: any }) => (
                                    <FormControl mt={4} isInvalid={form.errors.platform && form.touched.platform}>
                                        <FormLabel>Privacy Mode</FormLabel>
                                        <Select {...field}
                                                value={remoteConfigValue?.isPersonal ? 'isPersonal' : 'isPublic'}
                                                onChange={(e) => {
                                                    onPrivacyModeChange(e.target.value);
                                                    form.setFieldValue('platform', parseInt(e.target.value));
                                                }}
                                                placeholder="Select Privacy Mode">
                                            <option value="isPersonal">Private</option>
                                            <option value="isPublic">Public</option>
                                        </Select>
                                        <FormErrorMessage>{form.errors.platform}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="environment">
                                {({ field, form }: { field: any; form: any }) => (
                                    <FormControl mt={4} isInvalid={form.errors.platform && form.touched.platform}>
                                        <FormLabel>Select Environment</FormLabel>
                                        <Select {...field}
                                                value={remoteConfigValue?.isTest ? 'development' : 'production'}
                                                onChange={(e) => {
                                                    onEnvironmentChange(e.target.value);
                                                    form.setFieldValue('platform', parseInt(e.target.value));
                                                }}
                                                placeholder="Select platform">
                                            <option value="development">Development</option>
                                            <option value="production">Production</option>
                                        </Select>
                                        <FormErrorMessage>{form.errors.platform}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="platform">
                                {({ field, form }: { field: any; form: any }) => (
                                    <FormControl mt={4} isInvalid={form.errors.platform && form.touched.platform}>
                                        <FormLabel>Select Platform</FormLabel>
                                        <Select {...field}
                                                value={remoteConfigValue?.platform}
                                                onChange={(e) => {
                                                    updateRemoteConfigValue({platform: parseInt(e.target.value)});
                                                    form.setFieldValue('platform', parseInt(e.target.value));
                                                }}>
                                            <option value={Platform.All}>All</option>
                                            <option value={Platform.iOS}>iOS</option>
                                            <option value={Platform.Android}>Android</option>
                                        </Select>
                                        <FormErrorMessage>{form.errors.platform}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="segment">
                                {({ field, form }: { field: any; form: any }) => (
                                    <FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.country && form.touched.country}>
                                        <FormLabel>Select Segment</FormLabel>
                                        <HStack spacing={4} paddingTop={'10px'}>
                                            <Select
                                                value={getSegmentFilter().Condition}
                                                width={'200px'}
                                                onChange={(e: any) => {
                                                    if(e && e.target.value) {
                                                        updateSegmentCondition(parseInt(e.target.value));
                                                        //form.setFieldValue('countryFilter', parseInt(e.target.value));
                                                        //updateAbTestModal({countryFilter: parseInt(e.target.value)});
                                                        field.onChange(e);
                                                    }
                                                }}
                                            >
                                                <option value={FilterCondition.NoOp}>Select Filter</option>
                                                <option value={FilterCondition.Include}>Include</option>
                                                <option value={FilterCondition.Exclude}>Exclude</option>
                                            </Select>
                                            <Box flex={1}>
                                                <ChakraReactSelect
                                                    {...field}
                                                    options={segmentNames}
                                                    value={getSelectedSegments()}
                                                    isMulti
                                                    onChange={(e: any) => {
                                                        console.log(JSON.stringify(e));
                                                        if(e) {
                                                            console.log(e);
                                                            updateSegmentFilter(e as multiSelection[]);
                                                            //updateAbTestModal({country: e as { value: string, label: string }[]});
                                                        }
                                                        //field.onChange(e);
                                                    }}
                                                />
                                            </Box>
                                        </HStack>
                                        <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="country">
                                {({ field, form }: { field: any; form: any }) => (
                                    <FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.country && form.touched.country}>
                                        <FormLabel>Select Country</FormLabel>
                                        <HStack spacing={4} paddingTop={'10px'}>
                                            <Select
                                                value={getCountryFilter().Condition}
                                                width={'200px'}
                                                onChange={(e: any) => {
                                                    if(e && e.target.value) {
                                                        //form.setFieldValue('countryFilter', parseInt(e.target.value));
                                                        updateCountryFilter({Filter: FilterType.Country, Condition: parseInt(e.target.value), Values: getCountryFilter().Values});
                                                        //updateAbTestModal({countryFilter: parseInt(e.target.value)});
                                                        field.onChange(e);
                                                    }
                                                }}
                                            >
                                                <option value={0}>Select Filter</option>
                                                <option value={7}>Include</option>
                                                <option value={8}>Exclude</option>
                                            </Select>
                                            <Box flex={1}>
                                                <ChakraReactSelect
                                                    {...field}
                                                    options={countryList}
                                                    value={getSelectedCountries()}
                                                    isMulti
                                                    onChange={(e: any) => {
                                                        console.log(JSON.stringify(e));
                                                        if(e) {
                                                            console.log(e);
                                                            updateCountrySelection(e as multiSelection[]);
                                                            //updateAbTestModal({country: e as { value: string, label: string }[]});
                                                        }
                                                        //field.onChange(e);
                                                    }}
                                                />
                                            </Box>
                                        </HStack>
                                        <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="version">
                                {({ field, form }: { field: any; form: any }) => (
                                        <FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.country && form.touched.country}>
                                            <FormLabel>Version Filters</FormLabel>
                                            {remoteConfigValue?.filterOperations.filter(filter => filter.Filter === FilterType.Version).map((filter, index) => (
                                                <HStack spacing={4} paddingTop={'10px'} key={index}>
                                                    <Field
                                                        as={Select}
                                                        name={`filters.${index}.Condition`}
                                                        value={filter.Condition}
                                                        onChange={(e: any) => {
                                                            updateVersionFilterCondition(index, parseInt(e.target.value));
                                                        }}
                                                    >
                                                        {Object.entries(FilterCondition)
                                                            .filter(([key, condition]) => isNaN(Number(key))) // Filter out numeric keys
                                                            .map(([key, condition]) => (
                                                                <option key={key} value={condition}>{key}</option>
                                                            ))}
                                                    </Field>
                                                    <Box flex={1}>
                                                        <Field
                                                            as={Input}
                                                            name={`filters.${index}.Values`}
                                                            value={getVersionFilterValue(filter)}
                                                            placeholder="Filter Value"
                                                            width={'450px'}
                                                            onChange={(e: any) => {
                                                                updateVersionFilterValue(index, e.target.value);
                                                            }}
                                                        />
                                                    </Box>
                                                    {index === remoteConfigValue.filterOperations.filter(f => f.Filter === FilterType.Version).length - 1 ? (
                                                        <Button width={'350px'} colorScheme="blue" onClick={addVersionFilter}>Add Filter</Button>
                                                    ) : (
                                                        <Button width={'350px'} colorScheme="red" onClick={() => removeVersionFilter(index)}>Remove Filter</Button>
                                                    )}
                                                </HStack>
                                            ))}
                                            <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                                        </FormControl>
                                )}
                            </Field>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="outline" mr={3} onClick={disclosure.onClose}>Cancel</Button>
                        {updatingExistingVariable ? (
                        <Button colorScheme="blue" type="submit" isLoading={isSubmitting} >Update</Button>) : (
                        <Button colorScheme="blue" type="submit" isLoading={isSubmitting} >Create</Button>)
                        }
                    </ModalFooter>
                    </Form>
                )}
                </Formik>
            </ModalContent>
        </Modal>
    );
}

export default RemoteConfigVariableModal;
