import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Tooltip,
    Grid,
    GridItem,
    HStack,
    VStack,
    FormControl,
    Select,
    DrawerContent,
    DrawerCloseButton,
    DrawerBody,
    DrawerOverlay,
    Drawer,
    Text,
    CardBody,
    Stack,
    Heading,
    StackDivider,
    Card,
    Input,
    Spinner,
    Flex,
} from "@chakra-ui/react";
import { useColorMode } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import config from "../config";
import pako from "pako";
import UserDropdown from "../user_dropdown/user_dropdown";

const eventTypes = [
    { id: 0, name: "All" },
    { id: 1, name: "Analytics" },
    { id: 2, name: "UnityLog" },
];

function LogData({ userToken, selectedGameId }) {
    const [sessionList, setSessionList] = useState([]);
    const { colorMode, setColorMode } = useColorMode();
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [webSocket, setWebSocket] = useState(null);
    const [isUserSpecific, setIsUserSpecific] = useState(false);
    const [userName, setUserName] = useState(null);
    const [deviceIds, setDeviceIds] = useState([]);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const fetchUserList = async () => {
            try {
                const response = await fetch(`${config.LOGGER_ENDPOINT}/user/list`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                const data = await response.json();
                setUserList(data);
            } catch (error) {
                console.error("Error fetching user list:", error);
            }
        };

        fetchUserList();
    }, [userToken]);

    useEffect(() => {
        setColorMode("dark");
    }, []);

    const closeWebSocket = () => {
        if (webSocket) {
            webSocket.close();
        }
    };

    const connectWebSocket = useCallback(
        (userName, deviceIds) => {
            const endpoint = isUserSpecific ? `${config.WEBSOCKET_ENDPOINT}/user_session_list_stream/${selectedGameId}` : `${config.WEBSOCKET_ENDPOINT}/session_list/${selectedGameId}`;

            const newWebSocket = new WebSocket(endpoint);

            newWebSocket.onopen = (event) => {
                if (isUserSpecific) {
                    // Send user-specific data if fetching specific sessions
                    const data = {
                        user_name: userName,
                        device_ids: deviceIds,
                        token: userToken,
                    };
                    newWebSocket.send(JSON.stringify(data));
                } else {
                    newWebSocket.send(userToken); // Normal session list
                }
            };

            newWebSocket.onmessage = (event) => {
                const incomingSessionList = JSON.parse(event.data);
                // Check if the incoming data is an array
                if (!Array.isArray(incomingSessionList)) {
                    console.error("Incoming session list is not an array:", incomingSessionList);
                    return;
                }
                setSessionList(incomingSessionList); // Update session list
            };

            newWebSocket.onclose = (event) => {
                console.log("WebSocket is closed:", event);
            };

            newWebSocket.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            setWebSocket(newWebSocket);
        },
        [selectedGameId, isUserSpecific]
    );

    // Initial connection for general sessions
    useEffect(() => {
        if (isUserSpecific) {
            // Connect for user-specific sessions if isUserSpecific is true
            closeWebSocket();
            connectWebSocket(userName, deviceIds);
        } else {
            // Connect for general sessions if isUserSpecific is false
            closeWebSocket();
            connectWebSocket();
        }
    }, [isUserSpecific, userName, deviceIds]);

    const handleListSessions = (userName, deviceIds) => {
        setUserName(userName); // Store the userName
        setDeviceIds(deviceIds);
        setIsUserSpecific(true); // Enable user-specific mode
        setSessionList([]); // Clear the session list
    };

    const handleResetSessions = async () => {
        setUserName(null); // Clear the userName
        setDeviceIds([]); // Clear the deviceIds
        setIsUserSpecific(false); // Disable user-specific mode
        setSessionList([]); // Clear user-specific session list
    };

    const SelectedSessionRow = ({ sessionId }) => (
        <Tbody key={sessionId}>
            <Tr>
                <Td colSpan={9}>
                    <VStack spacing={1} align="stretch">
                        <TabbedSessionInfo userToken={userToken} selectedSessionId={sessionId} />
                    </VStack>
                </Td>
            </Tr>
        </Tbody>
    );

    return (
        <Box border={"2px"} borderRadius={"5px"} borderColor={"gray.600"} fontSize={"14px"}>
            <h2>
                <Box as="span" flex="1" textAlign="left">
                    <TableContainer border={"2px"} borderRadius={"5px"} borderColor={"gray.600"} size={"sm"}>
                        {<UserDropdown userToken={userToken} onListSessions={handleListSessions} onResetSessions={handleResetSessions} userList={userList} />}
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>User</Th>
                                    <Th>Platform</Th>
                                    <Th>Device Model</Th>
                                    <Th w="150px" maxW="150px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                        OS
                                    </Th>
                                    <Th>Game Version</Th>
                                    <Th>Unity Version</Th>
                                    <Th>Adjust Version</Th>
                                    <Th>IronSource Version</Th>
                                    <Th>Session Date</Th>
                                </Tr>
                            </Thead>
                            {sessionList.length > 0 &&
                                sessionList.map((session) => (
                                    <React.Fragment key={session.SessionId}>
                                        <Tbody key={`${session.SessionId}-body`}>
                                            <Tr onClick={() => setSelectedSessionId(selectedSessionId === session.SessionId ? null : session.SessionId)}>
                                                <Td>{session.UserName}</Td>
                                                <Td>{session.Platform}</Td>
                                                <Td>{session.DeviceModel}</Td>
                                                <Td w="150px" maxW="150px" isTruncated>
                                                    <Tooltip label={session.OSVersion} aria-label="Full OS Version">
                                                        {session.OSVersion}
                                                    </Tooltip>
                                                </Td>
                                                <Td>{session.GameVersion}</Td>
                                                <Td>{session.EngineVersion}</Td>
                                                <Td>{session.AdjustVersion}</Td>
                                                <Td>{session.IronsourceVersion}</Td>
                                                <Td>{convertAndFormatTimestamp(session.Timestamp)}</Td>
                                            </Tr>
                                        </Tbody>
                                        {selectedSessionId === session.SessionId && <SelectedSessionRow key={`${session.SessionId}-selected`} sessionId={selectedSessionId} />}
                                    </React.Fragment>
                                ))}
                            {sessionList.length === 0 && isUserSpecific && (
                                <Tbody>
                                    <Tr>
                                        <Td colSpan={9} textAlign="center">
                                            No sessions found for the selected user and device(s).
                                        </Td>
                                    </Tr>
                                </Tbody>
                            )}
                        </Table>
                    </TableContainer>
                </Box>
            </h2>
        </Box>
    );
}

function TabbedSessionInfo({ userToken, selectedSessionId }) {
    const [eventType, setEventType] = useState("All");
    const [filterString, setFilterString] = useState("");
    const [sessionLogList, setSessionLogList] = useState([]);

    useEffect(() => {
        let retries = 0;
        let webSocket;

        const connectWebSocket = () => {
            webSocket = new WebSocket(config.WEBSOCKET_ENDPOINT + "/log_stream/" + selectedSessionId);

            webSocket.onopen = (event) => {
                retries = 0; // Reset retries after a successful connection
                if (webSocket.readyState === WebSocket.OPEN) {
                    webSocket.send(userToken);
                }
            };

            webSocket.onmessage = async (event) => {
                let data;
                if (event.data instanceof Blob) {
                    const arrayBuffer = await event.data.arrayBuffer();
                    const uint8Array = new Uint8Array(arrayBuffer);
                    data = pako.inflate(uint8Array, { to: "string" });
                } else {
                    data = event.data;
                }

                let incomingLogList;
                try {
                    incomingLogList = JSON.parse(data);
                } catch (error) {
                    console.error("Error parsing incoming data:", error);
                    return;
                }

                if (!Array.isArray(incomingLogList)) {
                    console.error("Incoming log list is not an array:", incomingLogList);
                    return;
                }

                setSessionLogList((prevVariables) => {
                    const prevArray = Array.isArray(prevVariables) ? prevVariables : [];
                    const newLogs = incomingLogList.filter((incomingLog) => !prevArray.some((existingLog) => existingLog.EventNumber === incomingLog.EventNumber));
                    return [...newLogs, ...prevArray];
                });
            };

            webSocket.onclose = (event) => {
                console.log("WebSocket is closed:", event);
                // Try to reconnect if the connection was closed abnormally
                if (event.wasClean === false && retries < 5) {
                    retries++;
                    console.log(`Reconnecting... Attempt #${retries}`);
                    setTimeout(connectWebSocket, retries * 1000); // Exponential backoff
                }
            };

            webSocket.onerror = (error) => {
                console.error("WebSocket error:", error);
            };
        };

        connectWebSocket();

        return () => {
            if (webSocket.readyState === WebSocket.OPEN || webSocket.readyState === WebSocket.CONNECTING) {
                webSocket.close();
            }
        };
    }, [selectedSessionId]);

    if (sessionLogList.length === 0) {
        return (
            <Flex justify="center" align="center" minH="100vh">
                <Spinner />
            </Flex>
        );
    }

    return (
        <>
            <VStack spacing={1} align="stretch">
                <HStack spacing={"10px"}>
                    <FormControl paddingTop={"10px"} paddingBottom={"10px"} paddingLeft={"10px"} paddingRight={"10px"}>
                        <Select onChange={(selected_event_type) => setEventType(selected_event_type.target.value)}>
                            {eventTypes.map((event) => (
                                <option key={event.id} value={event.name}>
                                    {event.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <Input placeholder="Basic usage" onChange={(filter_text) => setFilterString(filter_text.target.value)} />
                </HStack>
                <SessionLogs eventType={eventType} filterString={filterString} sessionLogList={sessionLogList} />
            </VStack>
        </>
    );
}

function SessionLogs({ eventType, filterString, sessionLogList }) {
    const [filteredGameData, setFilteredGameData] = useState(null);
    const [selectedLogInfo, setSelectedLogInfo] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        setFilteredGameData(sessionLogList);
        filterGameData(sessionLogList, filterString);
    }, [sessionLogList, eventType, filterString]);

    function filterGameData(sessionLogList, filterString) {
        filterString = filterString.toLowerCase();
        let result = sessionLogList;
        if (eventType !== "All") {
            if (eventType === "Analytics") {
                result = result.filter((field) => PrintEventName(field) !== "Log");
            } else {
                result = result.filter((field) => PrintEventName(field) === "Log");
            }
        }

        if (filteredGameData && filterString.length > 0) {
            let tokens = filterString.split(" ");
            for (let i = 0; i < tokens.length; i++) {
                let token = tokens[i];
                if (token === "-") return;
                if (token.includes("-")) {
                    token = token.replace("-", "");
                    result = result.filter((field) => !JSON.stringify(field).toLowerCase().includes(token));
                } else {
                    result = result.filter((field) => JSON.stringify(field).toLowerCase().includes(tokens[i]));
                }
            }
        }
        setFilteredGameData(result);
    }

    function truncateString(str, num) {
        if (str === null) return "";
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    }

    return (
        <>
            <TableContainer>
                <OpenDrawer isOpen={isOpen} onClose={onClose} selectedLogInfo={selectedLogInfo} />
                <Table variant="simple" size="sm" border={"0px"}>
                    <Thead>
                        <Tr>
                            <Th width={"15%"}>Event Name</Th>
                            <Th width={"10%"}>Log Type</Th>
                            <Th width={"60%"}>Value</Th>
                            <Th width={"15%"}>Timestamp</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredGameData &&
                            filteredGameData.map((field, i) => (
                                <Tr
                                    key={field.Timestamp || i}
                                    onClick={() => {
                                        onOpen();
                                        setSelectedLogInfo(field);
                                    }}
                                >
                                    <Td>{PrintEventName(field)}</Td>
                                    <Td>{PrintLogType(field)}</Td>
                                    <Td>{truncateString(field.LogString, 120)}</Td>
                                    <Td>{convertAndFormatTimestamp(field.Timestamp)}</Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
}

function OpenDrawer({ isOpen, onClose, selectedLogInfo }) {
    const renderLogInfo = (info) => {
        let elements = [];
        Object.entries(info).forEach(([key, value]) => {
            if (key !== "_id" && value !== null && value.length !== 0) {
                if (typeof value === "object" && Object.keys(value).length > 0) {
                    elements.push(...renderLogInfo(value));
                } else {
                    elements.push(
                        <Box key={key}>
                            <Heading size="xs" textTransform="uppercase">
                                {key}
                            </Heading>
                            <Text pt="2" fontSize="sm" whiteSpace="pre-line">
                                {value}
                            </Text>
                        </Box>
                    );
                }
            }
        });
        return elements;
    };

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"md"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody width={"95%"}>
                        {selectedLogInfo && (
                            <Card>
                                <CardBody>
                                    <Stack divider={<StackDivider />} spacing="4">
                                        {renderLogInfo(selectedLogInfo)}
                                    </Stack>
                                </CardBody>
                            </Card>
                        )}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}

function convertAndFormatTimestamp(unixTimestamp) {
    // Create a Date object from the Unix timestamp
    const date = new Date(unixTimestamp * 1000);

    // Adjust for the UTC +3 timezone
    date.setHours(date.getHours());

    // Extract the individual date components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed in JavaScript
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milisseconds = String(date.getMilliseconds()).padStart(2, "0");

    // Construct the final formatted string
    const finalFormattedDate = `${hours}:${minutes}:${seconds}:${milisseconds} - ${day}/${month}/${year}`;

    return finalFormattedDate;
}

function PrintEventName(eventData) {
    if (eventData.EventName === null) {
        return "Log";
    }
    return eventData.EventName;
}

function PrintLogType(eventData) {
    if (eventData.EventName === null) {
        if (eventData.LogType === "Log") return "Log";
        return eventData.LogType.toLowerCase();
    }
    return "Event";
}

function LogView({ userToken, selectedGameId }) {
    return (
        <Grid height={"300px"} width={"100%"} templateRows="repeat(4, 1fr)" templateColumns="repeat(5, 1fr)" gap={4} paddingLeft={"12px"} paddingRight={"10px"}>
            <GridItem colSpan={6} paddingTop={"10px"}></GridItem>
            <GridItem paddingLeft={"20px"} colSpan={6}>
                <HStack spacing={"10px"}></HStack>
            </GridItem>
            <GridItem paddingLeft={"20px"} colSpan={6}>
                {userToken && selectedGameId && <LogData userToken={userToken} selectedGameId={selectedGameId} />}
            </GridItem>
        </Grid>
    );
}

export default LogView;
