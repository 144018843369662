import { LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ChakraProvider,
  Heading,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "axios";
import NavBar from "../navbar";
import config from "../config";
import globalStore from "../store";
import { Formik, Form, Field } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {loginFormSchema}  from "@repo/alictus-common/validation/loginSchema";
import {LoginCred} from '@repo/alictus-common/types/login/login_cred';


export default function LoginView() {
  const [error, setError] = useState<string>("");
  const [userToken, setUserToken] = useState<string | null>(null);
  const [tokenIsValid, setTokenIsValid] = useState<boolean>(false);
  const [roles, setRoles] = useState<string[]>([]);
  const setJWTToken = globalStore((state) => state.setJWTToken);

  // Handle login logic
  const handleLogin = async (values: LoginCred) => {
    try {
      const response = await axios.post(`${config.API_ENDPOINT}/login`, values);

      if (response.status === 200) {
        setUserToken(response.data.token);
        setRoles(response.data.roles);
        setJWTToken(response.data.token);
        sessionStorage.setItem("dashboardUserInfo", JSON.stringify(response.data));
        setTokenIsValid(true);
        setError("");
      }
    } catch (e) {
      console.error("Login failed:", e);
      setError("Login failed. Please check your credentials.");
    }
  };

  // Check if the token is valid when the component mounts
  useEffect(() => {
    const userData = sessionStorage.getItem("dashboardUserInfo");
    if (userData) {
      const data = JSON.parse(userData);
      if (data.token) {
        const decodedToken = JSON.parse(atob(data.token.split(".")[1])); // Decode the JWT payload
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

        if (decodedToken.exp && decodedToken.exp > currentTime) {
          setUserToken(data.token);
          setJWTToken(data.token); // Update the global store with the token
          setRoles(data.roles || []);
          setTokenIsValid(true);
        } else {
          sessionStorage.removeItem("dashboardUserInfo"); // Clear expired token
        }
      }
    }
  }, []); // Empty dependency array means this runs only on component mount

  // Render login or site based on token validity
  if (tokenIsValid && userToken) {
    return <NavBar userToken={userToken} setUserToken={setUserToken} roles={roles} avatarUrl={""} />;
  }

  return (
    <ChakraProvider>
      <Center minHeight="100vh">
        <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
          <Container maxW="xs">
            <Flex direction="column" align="center" mt={20}>
              <LockIcon w={8} h={8} marginBottom="40px" />
              <Heading fontSize="2xl">Login Page</Heading>
              <Box mt={4} w="100%">
                {error && (
                  <Box color="red.500" mb={4}>
                    {error}
                  </Box>
                )}
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={toFormikValidationSchema(loginFormSchema)}
                  onSubmit={(values) => handleLogin(values)}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <FormControl isInvalid={!!errors.email && touched.email} isRequired>
                        <FormLabel>Email Address</FormLabel>
                        <Field
                          as={Input}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email"
                        />
                        {errors.email && touched.email && (
                          <Box color="red.500" mt={1}>
                            {errors.email}
                          </Box>
                        )}
                      </FormControl>
                      <FormControl
                        isInvalid={!!errors.password && touched.password}
                        isRequired
                        mt={4}
                      >
                        <FormLabel>Password</FormLabel>
                        <Field
                          as={Input}
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Password"
                        />
                        {errors.password && touched.password && (
                          <Box color="red.500" mt={1}>
                            {errors.password}
                          </Box>
                        )}
                      </FormControl>
                      <Button colorScheme="blue" mt={6} width="full" type="submit">
                        Login
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Center>
    </ChakraProvider>
  );
}
