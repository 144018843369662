import {
    Button,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Grid,
    GridItem,
    HStack,
    VStack,
    Flex, IconButton,
} from '@chakra-ui/react'
import {Icon, PlusSquareIcon, ViewIcon} from "@chakra-ui/icons"
import {useDisclosure} from "@chakra-ui/hooks";
import React, {useState, useEffect, useRef} from 'react';
import {FaTrashAlt} from 'react-icons/fa';
import axios from 'axios';
import config from '../config';
import globalStore, {GlobalStore} from "../store";
import {formatTimestamp} from "../utility";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {CgCloseO} from "react-icons/cg";
import {DataSelector} from "../types/segment";
import SelectorModal from "./selector_modal";
import DeleteSelectorModal from "./delete_selector_modal";



async function fetchSelectorData(globalData: GlobalStore, setSelectors: React.Dispatch<React.SetStateAction<DataSelector[]>>) {
    let lastUpdated = 0; // implement this later
    try {
        while (true) {
            console.log("fetchSelectorData last updated timestamp: " + lastUpdated);
            let bearerToken = "Bearer " + globalData.JWTToken;
            let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector';
            const response = await axios.get(url, {headers: {Authorization: bearerToken}});
            let dataSelectors = response.data as DataSelector[];
            console.log("Segment Data: " + JSON.stringify(dataSelectors));
            if(dataSelectors){
                console.log("setting segment values");
                setSelectors(dataSelectors);
                //lastUpdated = getLatestUpdate(incomingRemoteConfigValues);
            }

            // Add a delay to prevent infinite loop
            await new Promise(resolve => setTimeout(resolve, 5000));
        }
    } catch (error) {
        console.error("Error fetching remote config data:", error);
    }
}

function SelectorDataView()
{
    let globalData = globalStore();
    const createSelector = useDisclosure();
    const editSelector = useDisclosure();
    const deleteSelector = useDisclosure();
    const [selectorToDelete, setSelectorToDelete] = useState<DataSelector>();
    const [selectedSelector, setSelectedSelector] = useState<DataSelector>();
    const [dataSelectors, setDataSelectors] = useState<DataSelector[]>([]);
    const hasFetchedData = useRef(false);

    useEffect(() => {
        if (!hasFetchedData.current) {
            console.log("Fetching selector data");
            hasFetchedData.current = true;
            fetchSelectorData(globalData, setDataSelectors);
        }
    }, []);

    const multiFunction = (disc: { onOpen: () => void }, selectorInstance: DataSelector) => {
        setSelectedSelector(selectorInstance);
        disc.onOpen();
    };

    const deleteSelectorModalOpen = (disc: { onOpen: () => void }, selectorInstance: DataSelector) => {
        setSelectorToDelete(selectorInstance);
        disc.onOpen();
    };

    function showCheckMark(isActive: boolean) {
        return (
            <Flex justify="center" align="center" width="100%" height="100%">
                {isActive ? (
                    <Icon as={BsFillCheckCircleFill} color={'green.200'} _hover={{ color: 'gray.300' }} />
                ) : (
                    <Icon as={CgCloseO} color={'red.200'} _hover={{ color: 'gray.300' }} />
                )}
            </Flex>
        );
    }

    function ViewButton({onOpen}: {onOpen: () => void}){
        return (
            <IconButton onClick={onOpen} aria-label='Add'
                        icon={<ViewIcon color={'gray.500'} _hover={{color: 'gray.300'}}/>}
                        backgroundColor={'transparent'} _hover={{bg: 'transparent', color: 'white'}}/>
        );
    }

    function PlusButton({ paddingTop, onOpen }: { paddingTop?: string; onOpen: () => void }) {
        return (
            <IconButton
                onClick={onOpen}
                paddingTop={paddingTop}
                aria-label='Add'
                icon={<PlusSquareIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
                backgroundColor={'transparent'}
                _hover={{ bg: 'transparent', color: 'white' }}
            />
        );
    }

    return (
        <Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>

            {deleteSelector.isOpen && selectorToDelete !== undefined &&(
                <DeleteSelectorModal selectorInstance={selectorToDelete} disclosure={deleteSelector}/>
            )}

            {editSelector.isOpen && (
                <SelectorModal
                    isOpen={editSelector.isOpen}
                    onClose={editSelector.onClose}
                    selectorInstance={selectedSelector}
                />
            )}

            {createSelector.isOpen && (
                <SelectorModal
                    isOpen={createSelector.isOpen}
                    onClose={createSelector.onClose}
                />
            )}

            <VStack  spacing={1} align='stretch'>
                <HStack flex={1} align={'center'}>
                    <Box paddingLeft={'10px'}><strong>Data Selectors</strong></Box><PlusButton onOpen={createSelector.onOpen} paddingTop={"0px"}/>
                </HStack>
                <TableContainer>
                    <Table variant='simple' size='md' border={'0px'}>
                        <Thead>
                            <Tr>
                                <Th width="150px">Name</Th>
                                <Th width="200px">Description</Th>
                                <Th width="200px">Creation Date</Th>
                                <Th width="150px">Last Update</Th>
                                <Th width="50px"><Flex justify="center" align="center" width="100%" height="100%">View</Flex></Th>
                                <Th width="50px"><Flex justify="center" align="center" width="100%" height="100%">Delete</Flex></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {dataSelectors.sort((a: DataSelector, b: DataSelector) => a.name.localeCompare(b.name)).map((field: DataSelector, i: number) => (
                                <Tr>
                                    <Td width="150px">{field.name}</Td>
                                    <Td width="200px">{field.description}</Td>
                                    <Td width="200px">{formatTimestamp(field.creationDate)}</Td>
                                    <Td width="150px">{formatTimestamp(field.lastUpdated)}</Td>
                                    <Td><Flex justify="center" align="center" width="100%" height="100%"><ViewButton onOpen={()=>(multiFunction(editSelector, field))} /></Flex></Td>
                                    <Td width="50px"><Flex justify="center" align="center" width="100%" height="100%">
                                        <Button
                                            onClick={() => {deleteSelectorModalOpen(deleteSelector, field)/*setDeleteRCKeyData(field);*/}}
                                            leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
                                            variant='simple'
                                        /></Flex>
                                    </Td>
                                </Tr>))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </Box>
    );
}

function SelectorView()
{
    return(
        <Grid
            height={'1px'}
            width={'100%'}
            templateRows='repeat(4, 1fr)'
            templateColumns='repeat(5, 1fr)'
            gap={2}
            paddingLeft={'12px'}
            paddingRight={'10px'}
        >
            <GridItem colSpan={6} padding={'15px'}>
            </GridItem>
            <GridItem paddingLeft={'20px'} colSpan={6}>
                <SelectorDataView/>
            </GridItem>
        </Grid>)
}
export default SelectorView;
