import React, {useEffect, useState, useCallback} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input, HStack, Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from "../store";
import {UserDevice} from "../types";

const AddDeviceIdModal = ({isOpen, onClose}: {isOpen: boolean; onClose: () => void;}) => {
    const [deviceList, setDeviceList] = useState<UserDevice[]>([]);
    const [deviceName, setDeviceName] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [showSubmitButton, setShowSubmitButton] = useState(true);
    let globalData = globalStore();

    const resetForm = () => {
        setDeviceName('');
        setDeviceId('');
    };

    const fetchDeviceData = useCallback(() => {
        let bearerToken = "Bearer " + globalData.JWTToken;
        axios.get(config.API_ENDPOINT + '/device', {headers: {Authorization: bearerToken}})
            .then((response) => {
                setDeviceList(response.data as UserDevice[]);
            })
            .catch((error) => {
                console.error("Error fetching device data:", error);
                setDeviceList([]);
            });
    }, [isOpen]);

    const handleSubmit = () => {
        console.log("handleSubmitCalled");
        console.log(deviceList);
        setShowSubmitButton(false);
        axios.post(config.API_ENDPOINT + '/device',
            deviceList,
            {headers: {Authorization: "Bearer " + globalData.JWTToken}})
            .then((response) => {
                console.log(response);
                resetForm();
                setShowSubmitButton(true);
                onClose();
            });
    };

    useEffect(() => {
        if (isOpen) {
            fetchDeviceData();
        }
    }, [isOpen, fetchDeviceData]);

    function updateDeviceId(index:number, device_id:string) {
        const temp = [...deviceList];
        temp[index].deviceId = device_id;
        setDeviceList(temp);
    }

    function updateDeviceName(index: number, device_name: string) {
        const temp = [...deviceList];
        temp[index].name = device_name;
        setDeviceList(temp);
    }

    function deleteDevice(index: number) {
        const temp = [...deviceList];
        temp.splice(index, 1);
        setDeviceList(temp);
    }

    function addDevice() {
        const temp = [...deviceList];
        temp.push({name: deviceName, deviceId: deviceId, userId: 0}); // userId is not used in the backend
        setDeviceName('')
        setDeviceId('');
        setDeviceList(temp);
        console.log(deviceList);
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW="56rem">
                <ModalHeader>Add Device Id</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody maxW={'600px'}>
                        {deviceList && deviceList.map((device, index) =>  (
                            <HStack key={index} paddingBottom={'10px'}  width={'850px'}>
                                <Input width={'350px'} value={device.name} onChange={(event) => updateDeviceName(index, event.target.value)}/>
                                <Input width={'350px'} value={device.deviceId} onChange={(event) => updateDeviceId(index, event.target.value)} />
                                <Button variant="outline" width={'150px'} colorScheme="red" mr={3} onClick={()=>deleteDevice(index)}>Delete</Button>
                            </HStack>))}
                        <HStack paddingBottom={'10px'}  width={'850px'}>
                        <Input  width={'350px'} placeholder='Device Name'  value={deviceName} onChange={(event) => setDeviceName(event.target.value)} />
                        <Input  width={'350px'} placeholder='IOS/Android Device Id'  value={deviceId} onChange={(event) => setDeviceId(event.target.value)} />
                            <Button variant="outline" width={'150px'} colorScheme="blue" mr={3} onClick={()=>addDevice()}>Add</Button>
                        </HStack>
                    </ModalBody>
                    <ModalFooter>
                        {showSubmitButton &&
                            <Button variant="outline" mr={3} onClick={()=>handleSubmit()}>Submit</Button>
                        }
                        {!showSubmitButton &&
                          <Spinner/>
                        }
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default AddDeviceIdModal;
